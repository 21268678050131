import React from "react";
import {
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox,
  Grid,
  Typography,
  Box,
} from "@mui/material";
import {
  HEARING_DIFFICULTY,
  HEARING_HISTORY_PROBLEM,
  HEARING_PROBLEM,
  LOSE_HEARING,
  SSA_REGISTERED,
} from "../../../utils/constants";

const ChiefCompliant = ({ fields, setFields }) => {
  return (
    <Box p={2}>
      <Typography color={"primary"} variant="h4">
        1. Please tell us about your hearing concerns:
      </Typography>
      <TextField
        value={fields.problems.please_tell_us_about_your_hearing_concerns}
        onChange={(e) =>
          setFields({
            ...fields,
            problems: {
              ...fields.problems,
              please_tell_us_about_your_hearing_concerns: e.target.value,
            },
          })
        }
        fullWidth
        multiline
        rows={2}
        placeholder="Your answer"
      />

      {/* Question 2 */}
      <Typography color={"primary"} variant="h4" sx={{ mt: 2 }}>
        2. My hearing is:
      </Typography>
      <RadioGroup
        value={fields.problems.hearing}
        onChange={(e, changeVal) => {
          setFields({
            ...fields,
            problems: {
              ...fields.problems,
              hearing: changeVal,
            },
          });
        }}
        row
      >
        <FormControlLabel
          value={HEARING_PROBLEM["BETTER IN RIGHT EAR"]}
          control={<Radio />}
          label="Better in Right Ear"
        />
        <FormControlLabel
          value={HEARING_PROBLEM["BETTER IN LEFT EAR"]}
          control={<Radio />}
          label="Better in Left Ear"
        />
        <FormControlLabel
          value={HEARING_PROBLEM["ABOUT SAME IN BOTH EAR"]}
          control={<Radio />}
          label="About same in both ears"
        />
        <FormControlLabel
          value={HEARING_PROBLEM.UNSURE}
          control={<Radio />}
          label="Unsure"
        />
      </RadioGroup>

      {/* Question 3 */}
      <Typography color={"primary"} variant="h4" sx={{ mt: 2 }}>
        3. Do you have difficulty hearing:
      </Typography>
      <Grid container spacing={2}>
        {[
          "noisy_place",
          "quiet_place",
          "restaurant",
          "television",
          "telephone",
          "one_on_one_talking",
        ].map((option, index) => (
          <Grid item xs={4} key={index}>
            <FormControlLabel
              value={option}
              onChange={(e) => {
                setFields({
                  ...fields,
                  problems: {
                    ...fields.problems,
                    hearing_difficulty: {
                      ...fields.problems.hearing_difficulty,
                      [option]: e.target.checked,
                    },
                  },
                });
              }}
              control={
                <Checkbox
                  checked={
                    fields?.problems?.hearing_difficulty?.[option] || false
                  }
                />
              }
              label={HEARING_DIFFICULTY[index]}
            />
          </Grid>
        ))}
      </Grid>

      <Box sx={{ mt: 3 }}>
        <Typography color={"primary"} variant="h4">
          4. Do you have history of:
        </Typography>
        <Grid container spacing={1}>
          {[
            { label: "Ear Pain", key: "ear_pain" },
            { label: "Ear Infection", key: "ear_infection" },
            { label: "Ear Surgery", key: "ear_surgery" },
            { label: "Fullness in Ear", key: "fullness_in_ear" },
            { label: "Tinnitus", key: "tinnitus" },
            { label: "Fluctuation in hearing", key: "fluctuation_in_hearing" },
          ].map((option, index) => (
            <Grid
              container
              item
              xs={6}
              key={index}
              alignItems="center"
              spacing={1}
            >
              <Grid item xs={4}>
                <Typography color={"primary"} variant="body1">
                  {option.label}:
                </Typography>
              </Grid>
              <Grid item>
                <RadioGroup
                  onChange={(e, change) => {
                    setFields({
                      ...fields,
                      problems: {
                        ...fields.problems,
                        hearing_history: {
                          ...fields.problems.hearing_history,
                          [option.key]: change,
                        },
                      },
                    });
                  }}
                  value={fields?.problems?.hearing_history?.[option.key]}
                  row
                >
                  <FormControlLabel
                    value={HEARING_HISTORY_PROBLEM.RIGHT}
                    control={<Radio />}
                    label="Right"
                  />
                  <FormControlLabel
                    value={HEARING_HISTORY_PROBLEM.LEFT}
                    control={<Radio />}
                    label="Left"
                  />
                  <FormControlLabel
                    value={HEARING_HISTORY_PROBLEM.BOTH}
                    control={<Radio />}
                    label="Both"
                  />

                  <FormControlLabel
                    value={HEARING_HISTORY_PROBLEM.NO}
                    control={<Radio />}
                    label="No"
                  />
                </RadioGroup>
              </Grid>
            </Grid>
          ))}
          {/* Add additional options without R/L */}

          {[
            { label: "Head Ache", key: "head_ache" },
            { label: "Dizziness Vomiting", key: "dizziness_vomiting" },
            { label: "Noise Exposure", key: "noise_exposure" },
          ].map((option, index) => (
            <Grid item xs={4} key={index}>
              <FormControlLabel
                value={option.key}
                onChange={(e) => {
                  setFields({
                    ...fields,
                    problems: {
                      ...fields.problems,
                      hearing_history: {
                        ...fields.problems.hearing_history,
                        [option.key]: e.target.checked,
                      },
                    },
                  });
                }}
                control={
                  <Checkbox
                    checked={fields?.problems?.hearing_history?.[option.key]}
                  />
                }
                label={option.label}
              />
            </Grid>
          ))}
        </Grid>
      </Box>

      {/* Question 5 */}
      <Typography color={"primary"} variant="h4" sx={{ mt: 2 }}>
        5. Have you had:
      </Typography>
      <Grid container spacing={2}>
        {[
          { label: "Diabetes", key: "diabetes" },
          { label: "Blood Pressure", key: "blood_pressure" },
          { label: "Heart Problem", key: "heart_problem" },
          { label: "Kidney Disease", key: "kidney_disease" },
          { label: "Tuberculosis", key: "tuberculosis" },
          { label: "Cancer", key: "cancer" },
          { label: "Other", key: "other" },
          {
            label: "Are you currently taking any medication?",
            key: "taking_medication",
          },
        ].map((option, index) => (
          <Grid item xs={4} key={index}>
            <FormControlLabel
              value={option.key}
              onChange={(e) => {
                setFields({
                  ...fields,
                  problems: {
                    ...fields.problems,
                    medical_history: {
                      ...fields.problems.medical_history,
                      [option.key]: e.target.checked,
                    },
                  },
                });
              }}
              control={
                <Checkbox
                  checked={fields?.problems?.medical_history?.[option.key]}
                />
              }
              label={option.label}
            />
          </Grid>
        ))}
      </Grid>

      {/* Question 6 */}
      <Typography color={"primary"} variant="h4" sx={{ mt: 2 }}>
        6. Have you had hearing aid:
      </Typography>
      <RadioGroup
        value={fields?.problems?.hearing_aid?.side}
        onChange={(e, change) => {
          setFields({
            ...fields,

            problems: {
              ...fields.problems,
              hearing_aid: {
                ...fields.problems.hearing_aid,
                side: change,
              },
            },
          });
        }}
        row
      >
        <FormControlLabel
          value={HEARING_HISTORY_PROBLEM.RIGHT}
          control={<Radio />}
          label="Right"
        />
        <FormControlLabel
          value={HEARING_HISTORY_PROBLEM.LEFT}
          control={<Radio />}
          label="Left"
        />
        <FormControlLabel
          value={HEARING_HISTORY_PROBLEM.BOTH}
          control={<Radio />}
          label="Both"
        />

        <FormControlLabel
          value={HEARING_HISTORY_PROBLEM.NO}
          control={<Radio />}
          label="No"
        />
      </RadioGroup>
      <TextField
        value={fields?.problems?.hearing_aid?.model}
        onChange={(e) => {
          setFields({
            ...fields,

            problems: {
              ...fields.problems,
              hearing_aid: {
                ...fields.problems.hearing_aid,
                model: e.target.value,
              },
            },
          });
        }}
        fullWidth
        placeholder="Model"
        sx={{ mt: 1 }}
      />

      {/* Question 7 */}
      <Typography color={"primary"} variant="h4" sx={{ mt: 2 }}>
        7. Do you have any family member with hearing loss?
      </Typography>
      <RadioGroup
        value={
          fields?.problems?.family_member_hearing_loss
            ?.has_family_member_with_hearing_loss
        }
        onChange={(e, change) => {
          setFields({
            ...fields,

            problems: {
              ...fields.problems,
              family_member_hearing_loss: {
                ...fields.problems.family_member_hearing_loss,
                has_family_member_with_hearing_loss: change,
              },
            },
          });
        }}
        row
      >
        <FormControlLabel
          value={SSA_REGISTERED.YES}
          control={<Radio />}
          label="YES"
        />
        <FormControlLabel
          value={SSA_REGISTERED.NO}
          control={<Radio />}
          label="NO"
        />
      </RadioGroup>
      <TextField
        value={fields?.problems?.family_member_hearing_loss?.who}
        onChange={(e) => {
          setFields({
            ...fields,

            problems: {
              ...fields.problems,
              family_member_hearing_loss: {
                ...fields.problems.family_member_hearing_loss,
                who: e.target.value,
              },
            },
          });
        }}
        fullWidth
        placeholder="Who?"
        sx={{ mt: 1 }}
      />
      <Typography color={"primary"} variant="body1" sx={{ mt: 1 }}>
        When did the family member lose hearing?
      </Typography>
      <RadioGroup
        value={fields?.problems?.family_member_hearing_loss?.when_lost_hearing}
        onChange={(e, change) => {
          setFields({
            ...fields,

            problems: {
              ...fields.problems,
              family_member_hearing_loss: {
                ...fields.problems.family_member_hearing_loss,
                when_lost_hearing: change,
              },
            },
          });
        }}
        row
      >
        <FormControlLabel
          value={LOSE_HEARING.BIRTH}
          control={<Radio />}
          label="Birth"
        />
        <FormControlLabel
          value={LOSE_HEARING.MID_LIFE}
          control={<Radio />}
          label="Mid-life"
        />
        <FormControlLabel
          value={LOSE_HEARING.LIFE_ONSET}
          control={<Radio />}
          label="Life-onset"
        />
      </RadioGroup>
      <TextField
        fullWidth
        value={
          fields?.problems?.family_member_hearing_loss?.additional_information
        }
        onChange={(e) => {
          setFields({
            ...fields,

            problems: {
              ...fields.problems,
              family_member_hearing_loss: {
                ...fields.problems.family_member_hearing_loss,
                additional_information: e.target.value,
              },
            },
          });
        }}
        multiline
        rows={2}
        placeholder="Any other information you would like to share:"
        sx={{ mt: 1 }}
      />

      {/* Conclusion */}
      <Typography color={"primary"} variant="h4" sx={{ mt: 2 }}>
        Conclusion:
      </Typography>
      <TextField
        fullWidth
        value={fields?.problems?.conclusion}
        onChange={(e) => {
          setFields({
            ...fields,
            problems: {
              ...fields.problems,
              conclusion: e.target.value,
            },
          });
        }}
        multiline
        rows={3}
        placeholder="Conclusion"
      />
    </Box>
  );
};

export default ChiefCompliant;
