import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import CreateController from "../../../pages/patient/CreateController";
import { Button, IconButton } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AdditionalInformation from "./AdditionalInformation";
import ChiefCompliant from "./ChiefCompliant";
import SubmitButton from "../../../components/button/SubmitButton";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const CaseHistoryController = ({
  fields,
  setFields,
  value,
  setValue,
  tab,
  setTab,
}) => {
  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  const params = useParams();
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        width: "100%",
        overflow: "hidden",
        position: "fixed",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          borderBottom: 1,
          borderColor: "divider",
          overflow: "hidden",
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "row", overflow: "hidden" }}>
          <IconButton
            sx={{ marginRight: "5px" }}
            onClick={() => {
              if (params?.id) {
                navigate(`/patient-details/${params.id}`);
              } else {
                setValue(0);
              }
            }}
          >
            <ArrowBackIcon sx={{ color: "#2D3B67" }} fontSize="medium" />
          </IconButton>

          <Tabs
            value={tab}
            onChange={handleChange}
            aria-label="basic tabs example"
            sx={{ height: "0px" }}
          >
            <Tab label="Additional Information" {...a11yProps(0)} />
            <Tab label="Chief Compliant" {...a11yProps(1)} />
          </Tabs>
        </Box>
      </Box>

      <Box height={"84vh"} overflow={"auto"} p={5}>
        {tab == 0 && (
          <AdditionalInformation fields={fields} setFields={setFields} />
        )}
        {tab == 1 && <ChiefCompliant fields={fields} setFields={setFields} />}
      </Box>
    </Box>
  );
};
export default CaseHistoryController;
