import { memo, useState } from "react";

import CreateController from "./CreateController";
import { useNavigate, useParams } from "react-router-dom";

const UpdatePatientDetails = ({ id }) => {
  const [value, setValue] = useState(id);
  const params = useParams();
  const navigate = useNavigate();
  return (
    <CreateController
      id={params.id}
      value={value}
      setValue={setValue}
      callBack={() => {
        navigate("/patient-details/" + params.id);
      }}
    />
  );
};
export default memo(UpdatePatientDetails);
