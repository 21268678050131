import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Typography,
} from "@mui/material";
import { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomInput from "../../components/inputs/CustomInput";
import { GENDER, USER_ROLES } from "../../utils/constants";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  calculateAge,
  findNameByRole,
  getAllowedRoles,
  titleCase,
} from "../../utils/helper";
import CustomDialog from "../../components/layouts/common/CustomDialog";
import { CenteredBox } from "../../components/layouts/OneViewBox";
import {
  IMPAIREMENT_TYPE,
  LEVEL_OF_HEARING_LOSS,
  TYPE_OF_HEARING_LOSS,
} from "../../utils/patient.constants";
import ModuleContainer from "../../components/layouts/common/ModuleContainer";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import SubmitButton from "../../components/button/SubmitButton";
import AudioGram from "./AudioGram";
import { useTheme, styled } from "@mui/material";
import COUNTRY_CODES from "../../utils/CountryCode";
import SelectDeviceui from "./SelectDeviceui";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import AsyncDropDown from "../../components/inputs/AsyncDropDown";
import { getUserApi } from "../../apis/user.api";

const BasicInformationUi = ({
  fields,
  setFields,
  loading,
  setIsAgeModified,
}) => {
  const { user } = useSelector((state) => state);
  return (
    <Box
      p={3}
      sx={{
        justifyContent: "center",
        width: "100vw",
        height: "100%",
        overflowY: "auto",
      }}
    >
      <Typography color="error">{fields.err}</Typography>
      <Grid spacing={5} xs={12} sm={12} md={12} container>
        <Grid item xs={6} md={6} sm={12} sx={{ height: "82vh" }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <CustomInput
                autoFocus={true}
                disabled={loading}
                value={fields.first_name}
                onChange={(e) =>
                  setFields({
                    ...fields,
                    err: "",
                    first_name: e.target.value,
                  })
                }
                type="text"
                label={"Full Name*"}
              />
            </Grid>
            <Grid item xs={6} md={6} sx={{}}>
              <DesktopDatePicker
                disableFuture
                inputFormat="DD/MM/yyyy"
                renderInput={(props) => <CustomInput {...props} />}
                autoFocus={true}
                label={"DOB*"}
                disabled={loading}
                value={fields.dob ? moment(fields.dob) : null}
                onChange={(val) => {
                  if (val && val.isValid()) {
                    const isoString = val.toISOString();
                    setFields({
                      ...fields,
                      err: "",
                      dob: isoString,
                      age: calculateAge(isoString),
                    });
                    setIsAgeModified(false); // Ensure further age modifications don't interfere
                  } else {
                    setFields({
                      ...fields,
                      err: "",
                      dob: "",
                      age: 0,
                    });
                  }
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <Box sx={{ height: "100%", width: "100%" }}>
                <CustomInput
                  disabled={loading}
                  value={fields.age}
                  onChange={(e) => {
                    setFields({ ...fields, age: e.target.value });
                  }}
                  onBlur={(e) => {
                    const dob = moment()
                      .set("date", 1)
                      .set("month", 5)
                      .add(-1 * e.target.value, "year");
                    setFields({
                      ...fields,
                      err: "",
                      dob: dob.toISOString(),
                    });
                  }}
                  type="number"
                  label={"Age*"}
                />
              </Box>
            </Grid>

            <Grid item xs={6} md={6}>
              <CustomInput
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Select
                        disableUnderline
                        value={fields.country_code}
                        onChange={(e) =>
                          setFields({
                            ...fields,
                            err: "",
                            country_code: e.target.value,
                          })
                        }
                        variant="standard"
                        size="small"
                      >
                        {COUNTRY_CODES.map((item) => (
                          <MenuItem key={item.code} value={item.dial_code}>
                            {item.code}({item.dial_code})
                          </MenuItem>
                        ))}
                      </Select>
                    </InputAdornment>
                  ),
                }}
                disabled={loading}
                value={fields.phone}
                onChange={(e) =>
                  setFields({
                    ...fields,
                    err: "",
                    phone: e.target.value,
                  })
                }
                type="number"
                label={"Phone No.*"}
              />
            </Grid>
            <Grid item xs={6} md={6}>
              <CustomInput
                disabled={loading}
                value={fields.email}
                onChange={(e) =>
                  setFields({
                    ...fields,
                    err: "",
                    email: e.target.value,
                  })
                }
                type="email"
                label={"Email"}
              />
            </Grid>

            <Grid
              item
              xs={12}
              sx={{
                width: "1280px",
                flexshrink: "0",
              }}
            >
              <CustomInput
                multiline
                rows={2}
                disabled={loading}
                value={fields.address}
                onChange={(e) =>
                  setFields({
                    ...fields,
                    err: "",
                    address: e.target.value,
                  })
                }
                type="address"
                label={"Address*"}
              />
            </Grid>
            <Grid
              item
              xs={4}
              md={4}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignitems: "center",
                paddingLeft: "16px",
                gap: "10px",
              }}
            >
              <CustomInput
                disabled={loading}
                value={fields.zip_code}
                onChange={(e) =>
                  setFields({
                    ...fields,
                    err: "",
                    zip_code: e.target.value,
                  })
                }
                type="number"
                label={"ZIP/Postal*"}
              />
            </Grid>

            <Grid
              item
              xs={4}
              md={4}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignitems: "center",
                gap: "10px",
                paddingLeft: "16px",
              }}
            >
              <CustomInput
                disabled={loading}
                value={fields.state}
                onChange={(e) =>
                  setFields({
                    ...fields,
                    err: "",
                    state: e.target.value,
                  })
                }
                type="text"
                label={"State*"}
              />
            </Grid>

            <Grid
              item
              xs={4}
              md={4}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignitems: "center",
                gap: "10px",
                paddingLeft: "16px",
              }}
            >
              <CustomInput
                disabled={loading}
                value={fields.city}
                onChange={(e) =>
                  setFields({
                    ...fields,
                    err: "",
                    city: e.target.value,
                  })
                }
                type="text"
                label={"City*"}
              />
            </Grid>
            <Grid item xs={12}>
              {user.data.role === USER_ROLES.ADMIN && (
                <AsyncDropDown
                  InputComponent={(props) => (
                    <CustomInput
                      label
                      placeholder="Select Audiologist"
                      {...props}
                    />
                  )}
                  lazyFun={async (props) => {
                    return await getUserApi({
                      ...props,
                      role: USER_ROLES.AUDIO_LOGIST,
                    });
                  }}
                  OptionComponent={({ option, ...rest }) => {
                    return (
                      <Box
                        sx={{
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                        }}
                        {...rest}
                      >
                        <Typography ml={3} variant="h5">
                          {option.name}
                        </Typography>
                      </Box>
                    );
                  }}
                  onChange={async (changedVal) => {
                    setFields({
                      ...fields,
                      err: "",
                      assigned: changedVal?._id,
                    });
                  }}
                  titleKey={"name"}
                  valueKey={"_id"}
                />
              )}
            </Grid>
            <Grid
              sx={{
                border: "1px solid navy ",
                borderRadius: "5px",
                marginLeft: "8px",
                marginTop: "16px",
                width: "400px",
                height: "45px",
                position: "relative",
              }}
            >
              <Grid
                sx={{
                  marginTop: "8px",
                  marginLeft: "8px",
                  position: "absolute",
                }}
              >
                <FormLabel
                  disabled={loading}
                  id="demo-row-radio-buttons-group-label"
                >
                  Gender*
                </FormLabel>

                <Box
                  sx={{
                    position: "absolute",
                    marginLeft: "73px",
                    top: "0px",
                    width: "1px",
                    height: "30px",
                    backgroundColor: "navy",
                  }}
                ></Box>

                <RadioGroup
                  sx={{
                    position: "absolute",
                    width: "400px",
                    top: "-6px",
                    left: "90px",
                  }}
                  value={fields.gender}
                  onChange={(e) =>
                    setFields({
                      ...fields,
                      err: "",
                      gender: e.target.value,
                    })
                  }
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                >
                  {Object.keys(GENDER).map((item) => (
                    <FormControlLabel
                      disabled={loading}
                      key={item}
                      value={GENDER[item]}
                      control={<Radio />}
                      label={item}
                    />
                  ))}
                </RadioGroup>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default BasicInformationUi;
