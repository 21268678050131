import { useState } from "react";
import {
  Box,
  Chip,
  CircularProgress,
  IconButton,
  Popover,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  Cancel,
  Edit,
  Info,
  KeyboardArrowRight,
  QrCode,
} from "@mui/icons-material";

import QRCode from "react-qr-code";
import CustomDialog from "../../components/layouts/common/CustomDialog";
import { useDispatch } from "react-redux";
import { openModal } from "../../store/actions/modalAction";
import { CenteredBox } from "../../components/layouts/common/boxes";

export const QrCodeModal = ({ name, id }) => {
  return (
    <>
      <Stack
        direction={"row"}
        sx={{ display: "flex", alignItems: "center" }}
        mt={2}
        spacing={4}
      >
        <QRCode
          size={90}
          style={{ height: "auto", maxWidth: "100%" }}
          value={id}
          viewBox={`0 0 100 100`}
        />
      </Stack>
    </>
  );
};

export const QrCodeButton = ({ params }) => {
  const dispatch = useDispatch();
  const handleClick = (event) => {
    dispatch(
      openModal(
        <QrCodeModal
          name={params.first_name + " " + params.last_name}
          id={params._id}
        />,
        "xs",
        undefined,
        "patient-qr"
      )
    );
  };

  return (
    <>
      <Tooltip title="view Qr Code">
        <IconButton
          variant="contained"
          sx={{ padding: 0 }}
          onClick={handleClick}
        >
          <QrCode />
        </IconButton>
      </Tooltip>
    </>
  );
};
