import { memo, useEffect, useMemo, useState } from "react";
import useValidate from "../../store/hooks/useValidator";
import CreateUi from "./CreateUi";
import { useDispatch } from "react-redux";
import { callApiAction } from "../../store/actions/commonAction";

import { closeModal } from "../../store/actions/modalAction";
import { useNavigate, useParams } from "react-router-dom";
import { openModal } from "../../store/actions/modalAction";

import { calculateAge, validateEmail, validatePhone } from "../../utils/helper";
import {
  addPatientApi,
  getPatientByIdApi,
  updatePateintAudioGram,
  updatePatientField,
} from "../../apis/patient.api";
import { GENDER, SNACK_BAR_VARIETNS } from "../../utils/constants";
import {
  DEVICES,
  DEVICES_CHANNEL,
  DEVICES_TUBE,
  DOM_SIZE,
  DOM_TYPE,
  IMPAIREMENT_TYPE,
  LEVEL_OF_HEARING_LOSS,
  LISTENING_SIDE,
  TYPE_OF_HEARING_LOSS,
} from "../../utils/patient.constants";
import { callSnackBar } from "../../store/actions/snackbarAction";
import moment from "moment";
// import InSituConnectionController from "../inSituAudiometry/InSituConnectionController";

const CreateController = ({
  callBack = () => {},
  id,
  value,
  setValue,
  later,
}) => {
  const validate = useValidate();
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();

  const modalKey = "patient";
  const title = "Patients ";
  const createApi = addPatientApi;

  const getByIdApi = getPatientByIdApi;

  const [loading, setLoading] = useState(false);
  // const [value, setValue] = useState(params?.value === "audiogram" ? 1 : 0);
  const [create, setCreate] = useState(false);
  console.log("params", params);
  const updateApi =
    value == 0 || value == 4 ? updatePatientField : updatePateintAudioGram;
  const inSituAudiometry = () => {
    // dispatch(
    //   openModal(
    //     <InSituConnectionController />,
    //     "xl",
    //     true,
    //     "in-situ-connection"
    //   )
    // );
  };

  useEffect(() => {
    if (later) {
      updateFunction();
    }
  }, [later]);

  const [fields, setFields] = useState({
    err: "",
    id,
    first_name: "",
    middle_name: "",
    last_name: "",
    email: null,
    phone: "",
    state: "",
    city: "",
    zip_code: "",
    country_code: "+91",
    dob: moment().toISOString(),
    age: calculateAge(moment().toISOString()),
    address: "",
    gender: GENDER.MALE,
    type_of_loss: TYPE_OF_HEARING_LOSS.CONDUCTIVE,
    level_of_loss: LEVEL_OF_HEARING_LOSS.MILD,
    impairment_type: IMPAIREMENT_TYPE.UNILATERAL,
    left_ear_loss: 0,
    right_ear_loss: 0,
    allowed_ac: true,
    allowed_bc: false,
    allowed_ucl: false,
    lf1: null,
    lf2: null,
    lf3: null,
    lf4: null,
    lf5: null,
    lf6: null,
    lf7: null,
    lf8: null,
    rf1: null,
    rf2: null,
    rf3: null,
    rf4: null,
    rf5: null,
    rf6: null,
    rf7: null,
    rf8: null,

    blf1: null,
    blf2: null,
    blf3: null,
    blf4: null,
    blf5: null,
    blf6: null,
    blf7: null,
    blf8: null,
    brf1: null,
    brf2: null,
    brf3: null,
    brf4: null,
    brf5: null,
    brf6: null,
    brf7: null,
    brf8: null,

    ulf1: null,
    ulf2: null,
    ulf3: null,
    ulf4: null,
    ulf5: null,
    ulf6: null,
    ulf7: null,
    ulf8: null,
    urf1: null,
    urf2: null,
    urf3: null,
    urf4: null,
    urf5: null,
    urf6: null,
    urf7: null,
    urf8: null,

    mlf1: null,
    mlf2: null,
    mlf3: null,
    mlf4: null,
    mlf5: null,
    mlf6: null,
    mlf7: null,
    mlf8: null,
    mrf1: null,
    mrf2: null,
    mrf3: null,
    mrf4: null,
    mrf5: null,
    mrf6: null,
    mrf7: null,
    mrf8: null,

    assigned: null,

    right_fitted_device: {
      device_type: null,
      device_tube: null,
      device_channel: DEVICES_CHANNEL.CHANNEL_32,
      dom_type: null,
      dom_size: DOM_SIZE.SMALL,
      device_side: LISTENING_SIDE.RIGHT,
      selected: false,
    },

    left_fitted_device: {
      device_type: null,
      device_tube: null,
      device_channel: DEVICES_CHANNEL.CHANNEL_32,
      dom_type: null,
      dom_size: DOM_SIZE.SMALL,
      device_side: LISTENING_SIDE.LEFT,
      selected: false,
    },

    district: "",
    occupation_standard: "",
    language: "",
    aadhar_no: "",
    ssa_registered: false,
    disablity_id_no: "",
    family_person_information: {
      person_name: "",
      person_phone_no: "",
      email: "",
      consanguinitu: "",
      informant: null,
    },
    wehear_executive_name: "",
    problems: {
      please_tell_us_about_your_hearing_concerns: "",
      hearing: null,
      medical_history: {
        diabetes: false,
        blood_pressure: false,
        heart_problem: false,
        kidney_disease: false,
        tuberculosis: false,
        cancer: false,
        other: "",
        taking_medication: false,
      },
      hearing_difficulty: {
        noisy_place: false,
        quiet_place: false,
        restaurant: false,
        television: false,
        telephone: false,
        one_on_one_talking: false,
      },
      hearing_history: {
        ear_pain: null,
        ear_infection: null,
        ear_surgery: null,
        tinnitus: null,
        fluctuation_in_hearing: null,
        head_ache: false,
        dizziness_vomiting: false,
        noise_exposure: false,
        fullness_in_ear: null,
      },
      hearing_aid: {
        had_hearing_aid: false,
        side: null,
        model: "",
      },
      family_member_hearing_loss: {
        has_family_member_with_hearing_loss: false,
        who: "",
        when_lost_hearing: null,
        additional_information: "",
      },
      conclusion: "",
    },
  });
  const [originalDocument, setOriginalDocument] = useState({});

  const validationSchemaForCreate = useMemo(
    () => [
      [
        {
          required: true,
          value: fields.first_name,
          field: "First Name",
        },
        {
          required: true,
          value: fields.phone,
          field: "Phone No.",
        },
        {
          required: true,
          value: fields.state,
          field: "state",
        },
        {
          required: true,
          value: fields.city,
          field: "city.",
        },
        {
          required: true,
          value: fields.zip_code,
          field: "zip_code",
        },

        {
          value: fields.email,
          field: "Email",
          custom: () => {
            if (
              fields.email &&
              fields.email != "" &&
              !validateEmail(fields.email)
            ) {
              return "Email is invalid";
            }
            return true;
          },
        },
        {
          required: true,
          value: fields.dob,
          field: "Date of Birth",
        },
        {
          required: true,
          value: fields.address,
          field: "Address",
        },
      ],
      [
        {
          field: "Left",
          custom: () => {
            for (let i of [1, 2, 3, 4, 5, 6, 7, 8]) {
              for (let j of ["l", "r"]) {
                const freq = j + "f" + i;

                if (fields[freq] < 0 || fields[freq] > 120) {
                  return (
                    "All " +
                    (j == "l" ? "Left" : "Right") +
                    " Ear  Threshold should be between 0 to 12  0"
                  );
                }
              }
            }

            return true;
          },
        },
      ],
      [
        {
          field: "Left",
          custom: () => {
            for (let i of [1, 2, 3, 4, 5, 6, 7, 8]) {
              for (let j of ["l", "r"]) {
                const freq = "b" + j + "f" + i;
                if (fields[freq] < 0 || fields[freq] > 120) {
                  return (
                    "All " +
                    (j == "l" ? "Left" : "Right") +
                    " Ear  Threshold should be between 0 to 120"
                  );
                }
              }
            }

            return true;
          },
        },
      ],
    ],
    [fields]
  );

  const validationSchemaForUpdate = useMemo(
    () => [
      [
        {
          required: true,
          value: fields.first_name,
          field: "First Name",
        },
        {
          required: true,
          value: fields.phone,
          field: "Phone No.",
        },

        {
          value: fields.email,
          field: "Email",
          custom: () => {
            if (
              fields.email &&
              fields.email != "" &&
              !validateEmail(fields.email)
            ) {
              return "Email is invalid";
            }
            return true;
          },
        },
        {
          required: true,
          value: fields.dob,
          field: "Date Of Birth",
        },
        {
          required: true,
          value: fields.address,
          field: "Address",
        },
      ],
      [
        {
          field: "Left",
          custom: () => {
            for (let i of [1, 2, 3, 4, 5, 6, 7, 8]) {
              for (let j of ["l", "r"]) {
                const freq = j + "f" + i;
                if (fields[freq] < 0 || fields[freq] > 120) {
                  return (
                    "All " +
                    (j == "l" ? "Left" : "Right") +
                    " Ear  Threshold should be between 0 to 120"
                  );
                }
              }
            }

            return true;
          },
        },
      ],
      [
        {
          field: "Left",
          custom: () => {
            for (let i of [1, 2, 3, 4, 5, 6, 7, 8]) {
              for (let j of ["l", "r"]) {
                const freq = "b" + j + "f" + i;
                if (fields[freq] < 0 || fields[freq] > 120) {
                  return (
                    "All " +
                    (j == "l" ? "Left" : "Right") +
                    " Ear  Threshold should be between 0 to 120"
                  );
                }
              }
            }

            return true;
          },
        },
      ],
    ],
    [fields]
  );

  const createFunction = async () => {
    const validationResponse = validate(validationSchemaForCreate[value]);

    if (validationResponse === true) {
      if (value == 0) {
        setFields({ ...fields, err: "" });
      }

      const passData = { ...fields };
      if (!fields.email && fields.email == "") {
        delete passData["email"];
      }

      setLoading(true);
      dispatch(
        callApiAction(
          async () => await createApi(passData),
          async (response) => {
            await callBack(response);
            setLoading(false);

            setFields({ ...fields, id: response?._id });
            if (value == 0) {
              setValue(1);
              setCreate(true);
            }
            dispatch(
              callSnackBar(
                title + " Created Successfully",
                SNACK_BAR_VARIETNS.suceess
              )
            );
            // dispatch(closeModal(modalKey));
          },
          (err) => {
            setLoading(false);
            setFields({ ...fields, err });
          }
        )
      );
    } else {
      setFields({ ...fields, err: validationResponse });
    }
  };

  const updateFunction = async () => {
    const validationResponse = validate(validationSchemaForUpdate[value]);
    let updatedData = { id };

    for (let field in fields) {
      if (later) {
        if (field === "right_fitted_device" || field === "left_fitted_device") {
          continue;
        }
      }
      if (
        originalDocument[field] &&
        // fields[field] &&
        fields[field] != originalDocument[field]
      ) {
        updatedData[field] = fields[field];
      }
      if (!originalDocument[field]) {
        updatedData[field] = fields[field];
      }
    }

    updatedData["allowed_ac"] = fields["allowed_ac"];
    updatedData["allowed_bc"] = fields["allowed_bc"];
    updatedData["allowed_ucl"] = fields["allowed_ucl"];
    if (!later) {
      updatedData["right_fitted_device"] = fields["right_fitted_device"];
      updatedData["left_fitted_device"] = fields["left_fitted_device"];
    }

    if (validationResponse === true) {
      if (value == 0 && !id) {
        setValue(1);
        setFields({ ...fields, err: "" });
        return;
      }
      console.log("this is updated data updatedData", updatedData);
      setLoading(true);
      dispatch(
        callApiAction(
          async () => await updateApi(updatedData),
          async (response) => {
            await callBack(response, updatedData);
            setLoading(false);
            dispatch(
              callSnackBar(
                title + " Updated Successfully",
                SNACK_BAR_VARIETNS.suceess
              )
            );
            dispatch(closeModal(modalKey));
            if (!create) {
              navigate(`/patient-details/${id}`);
            } else {
              navigate("/patient-details");
            }
          },
          (err) => {
            setLoading(false);
            setFields({ ...fields, err });
          }
        )
      );
    } else {
      setFields({ ...fields, err: validationResponse });
    }
  };

  const onSubmit = async (e) => {
    console.log("this is nice", fields);
    e.preventDefault();
    if (fields.id) {
      await updateFunction();
    } else {
      createFunction();
    }
  };

  const fetchById = (id) => {
    setLoading(true);
    dispatch(
      callApiAction(
        async () => await getByIdApi({ id }),
        async (response) => {
          setFields({
            ...fields,
            ...response,
            id: response._id,
            age: moment(response.dob).toISOString(),
          });
          setOriginalDocument(response);
          setLoading(false);
        },
        (err) => {
          setFields({ ...fields, err });
          setLoading(false);
        }
      )
    );
  };

  useEffect(() => {
    if (id) {
      fetchById(id);
      console.log("this is fetch for", fields);
    }
  }, [id]);

  return (
    <CreateUi
      setValue={setValue}
      value={value}
      inSituAudiometry={inSituAudiometry}
      modalKey={modalKey}
      title={title}
      isUpdate={id}
      loading={loading}
      fields={fields}
      onSubmit={onSubmit}
      setFields={setFields}
    />
  );
};
export default memo(CreateController);
