import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Typography,
} from "@mui/material";
import { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomInput from "../../components/inputs/CustomInput";
import { GENDER, USER_ROLES } from "../../utils/constants";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  calculateAge,
  findNameByRole,
  getAllowedRoles,
  titleCase,
} from "../../utils/helper";
import CustomDialog from "../../components/layouts/common/CustomDialog";
import { CenteredBox } from "../../components/layouts/OneViewBox";
import {
  IMPAIREMENT_TYPE,
  LEVEL_OF_HEARING_LOSS,
  TYPE_OF_HEARING_LOSS,
} from "../../utils/patient.constants";
import ModuleContainer from "../../components/layouts/common/ModuleContainer";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import SubmitButton from "../../components/button/SubmitButton";
import AudioGram from "./AudioGram";
import { useTheme, styled } from "@mui/material";
import COUNTRY_CODES from "../../utils/CountryCode";
import SelectDeviceui from "./SelectDeviceui";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import AsyncDropDown from "../../components/inputs/AsyncDropDown";
import { getUserApi } from "../../apis/user.api";
import BasicInformationUi from "./BasicInformationUi";
import CaseHistoryController from "./CaseHistory/CaseHistoryController";

const CreateUi = ({
  value,
  setValue,
  isUpdate,
  fields,
  setFields,
  loading,
  onSubmit,
}) => {
  useEffect(() => {
    console.log("value", value);
  }, [value]);

  const { tabData } = useLocation()?.state || {};

  const [tab, setTab] = useState(tabData || 0);
  const onSave = (e) => {
    try {
      if (tabData != undefined) {
        onSubmit(e);
      } else {
        if (tab == 0) {
          setTab(1);
        } else {
          setValue(0);
          setTab(0);
        }
      }
    } catch (error) {}
  };

  const isDeviceSelect =
    (fields.right_fitted_device.device_tube &&
      fields.right_fitted_device.dom_type) ||
    (fields.left_fitted_device.device_tube &&
      fields.left_fitted_device.dom_type)
      ? true
      : false;

  const isAudioGranSelect =
    (fields.lf2 != null &&
      fields.lf3 != null &&
      fields.lf4 != null &&
      fields.lf5 != null &&
      fields.lf6 != null &&
      fields.lf7 != null) ||
    (fields.rf2 != null &&
      fields.rf3 != null &&
      fields.rf4 != null &&
      fields.rf5 != null &&
      fields.rf6 != null &&
      fields.rf7 != null)
      ? true
      : false;

  const { user } = useSelector((state) => state);
  const theme = useTheme();
  const dispatch = useDispatch();
  const [isAgeModified, setIsAgeModified] = useState(false);
  useEffect(() => {
    if (isAgeModified) {
      let timeOut = setTimeout(() => {
        if (
          fields.age !== null &&
          fields.age !== undefined &&
          !isNaN(fields.age) &&
          fields.age !== 0
        ) {
          const dob = moment()
            .set("date", 1)
            .set("month", 5)
            .subtract(fields.age, "years");

          if (dob.isValid()) {
            setFields((prevFields) => ({
              ...prevFields,
              err: "",
              dob: dob.toISOString(),
            }));
          } else {
            setFields((prevFields) => ({
              ...prevFields,
              err: "Invalid date of birth",
            }));
          }
        }
      }, 500);

      return () => clearTimeout(timeOut);
    }
  }, [fields.age, isAgeModified]);

  const navigate = useNavigate();
  const params = useParams();
  const letter = (name) => {
    let newName = name?.charAt(0).toUpperCase();
    let flag = false;
    for (let i = 1; i < name?.length; i++) {
      if (name?.charAt(i) == " ") {
        flag = true;
      } else {
        if (flag) {
          newName = newName + " " + name?.charAt(i).toUpperCase();
          flag = false;
        } else {
          newName = newName + name?.charAt(i).toLowerCase();
        }
      }
    }
    return newName;
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          maxHeight: "100%",
          overflowY: "hidden",

          flex: 1,
          borderRadius: "10px",
          marginTop: "10px",
          overflow: "hidden",
        }}
      >
        {/* <ModuleContainer
            containerProps={{
                component: "form",
                onSubmit
            }}
            loading={loading}
            err={fields.err}

        > */}

        {loading ? (
          <CenteredBox>
            <CircularProgress />
          </CenteredBox>
        ) : (
          <Grid
            item
            xs={12}
            md={12}
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              flex: 1,
              flexDirection: "column",
              // overflow: "auto",
              // scrollbarWidth: "none  ",
            }}
          >
            {params?.id && value != 4 && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  borderBottom: "1px solid #DDDDDD",
                }}
              >
                <Typography ml={1} color={"#2D3B67"} variant="h3">
                  <IconButton
                    sx={{ marginRight: "5px" }}
                    onClick={() => {
                      navigate(`/patient-details/${params.id}`);
                    }}
                  >
                    <ArrowBackIcon
                      sx={{ color: "#2D3B67" }}
                      fontSize="medium"
                    />
                  </IconButton>

                  {fields?.first_name?.charAt(0).toUpperCase() +
                    fields?.first_name?.slice(1).toLowerCase()}
                </Typography>
                {/* {value == 0 && (
                  <Box>
                    <Box mr={2} mt={2}>
                      <Button
                        onClick={() => {
                          setValue(4);
                        }}
                        sx={{
                          textDecoration: "underline",
                          fontFamily: "League spartan",
                          fontSize: "20px",
                          fontWeight: "bold",
                        }}
                      >
                        Case History
                      </Button>
                    </Box>
                  </Box>
                )} */}
              </Box>
            )}
            {
              <>
                {value == 0 && (
                  <BasicInformationUi
                    setIsAgeModified={setIsAgeModified}
                    fields={fields}
                    setFields={setFields}
                    loading={loading}
                  />
                )}
                {value == 1 && (
                  <AudioGram
                    fields={fields}
                    setFields={setFields}
                    value={value}
                    setValue={setValue}
                  />
                )}
                {value == 2 && (
                  <SelectDeviceui fields={fields} setFields={setFields} />
                )}
                {/* {value == 3 && <BasicInformationUi />} */}

                {value == 4 && (
                  <CaseHistoryController
                    tab={tab}
                    setTab={setTab}
                    fields={fields}
                    value={value}
                    setValue={setValue}
                    setFields={setFields}
                  />
                )}

                <Box
                  mt={6}
                  sx={{
                    position: "fixed",
                    bottom: 0,
                    left: 0,
                    display: "flex",
                    alignItems: "center",
                    // zIndex: 1,
                    height: "8vh",
                    width: "100%",
                    backgroundColor: "white",
                    padding: "5px",
                    justifyContent: "flex-end",
                    borderTop: "1px solid #ccc",
                  }}
                >
                  {/* {value > 0 && !isUpdate && (
                                <Box mr={2}>
                                    <SubmitButton
                                        onClick={() => {
                                            setValue(value - 1);
                                        }}
                                        variant="text"
                                        type="button"
                                        sx={{
                                            minWidth: "200px",
                                            textDecoration: "underline !important",
                                        }}
                                        title="Back"
                                    />
                                </Box>
                            )} */}

                  <Box>
                    {(value == 0 ||
                      (value == 2 && isDeviceSelect) ||
                      (isUpdate && value == 1 && isAudioGranSelect)) && (
                      <>
                        {console.log(
                          "this is isDeviceSelect",
                          isDeviceSelect,
                          value
                        )}
                        <SubmitButton
                          type="submit"
                          onClick={onSubmit}
                          sx={{ minWidth: "200px" }}
                          title={value == 0 && !isUpdate ? "Next" : "Save"}
                        />
                      </>
                    )}

                    {value == 1 && isAudioGranSelect && !isUpdate && (
                      <SubmitButton
                        onClick={() => {
                          setValue(value + 1);
                        }}
                        sx={{ minWidth: "200px" }}
                        title={"Next"}
                      />
                    )}

                    {value == 4 && (
                      <SubmitButton
                        onClick={onSave}
                        sx={{ minWidth: "200px" }}
                        title={"Save"}
                      />
                    )}
                  </Box>
                </Box>
              </>
            }
          </Grid>
        )}
        {/* </ModuleContainer > */}
      </Box>
    </>
  );
};
export default memo(CreateUi);
