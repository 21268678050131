import React from "react";
import {
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox,
  Grid,
  Typography,
  Box,
  IconButton,
} from "@mui/material";
import {
  HEARING_DIFFICULTY,
  HEARING_HISTORY_PROBLEM,
  HEARING_PROBLEM,
  LOSE_HEARING,
  SSA_REGISTERED,
} from "../../utils/constants";
import { findObjectKeyByValue } from "../../utils/helper";
import { INFORMANT } from "../../utils/constants";
import { useTheme } from "@emotion/react";
import { Edit } from "@mui/icons-material";
import { closeModal } from "../../store/actions/modalAction";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  diseaseArray,
  hearingDifficultyArr,
  historyArray1,
  historyArray2,
} from "../../utils/patient.constants";

const PatientAdditionalInformationView = ({ data }) => {
  const theme = useTheme();
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <Box p={2}>
      <Box sx={{ border: "1px solid", borderRadius: "4px" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            borderBottom: "1px solid",
            borderTopRightRadius: "4px",
            borderTopLeftRadius: "4px",
            p: 2,
            gap: 2,
            backgroundColor: "#EDF0F7",
          }}
        >
          <Typography color={"black"} variant="h4">
            Personal information{" "}
          </Typography>
          <Edit
            onClick={() => {
              let route =
                "/patient-details/" + params.id + "/update/additional";
              dispatch(closeModal("information"));
              navigate(route, { state: { tabData: 0 } });
            }}
            sx={{ cursor: "pointer" }}
            fontSize={theme.typography.h5.fontSize}
            color="black"
          />
        </Box>
        <Grid container spacing={2} p={2}>
          <Grid item xs={4} md={4}>
            <Box sx={{ display: "flex", gap: "20px" }}>
              <Typography>Language : </Typography>
              <Typography color={"grey"}>{data?.language || "NA"}</Typography>
            </Box>
          </Grid>
          <Grid item xs={4} md={4}>
            <Box sx={{ display: "flex", gap: "20px" }}>
              <Typography>Occupation/Standard : </Typography>
              <Typography color={"grey"}>
                {data?.occupation_standard || "NA"}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={4} md={4}>
            <Box sx={{ display: "flex", gap: "20px" }}>
              <Typography>Aadhar Card No : </Typography>
              <Typography color={"grey"}>{data?.aadhar_no || "NA"}</Typography>
            </Box>
          </Grid>
          <Grid item xs={4} md={4}>
            <Box sx={{ display: "flex", gap: "20px" }}>
              <Typography>SSA Registered : </Typography>
              <Typography color={"grey"}>
                {" "}
                {findObjectKeyByValue(
                  data?.ssa_registered || false,
                  SSA_REGISTERED
                )}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={4} md={4}>
            <Box sx={{ display: "flex", gap: "20px" }}>
              <Typography>Disability ID No : </Typography>
              <Typography color={"grey"}>
                {data?.disablity_id_no || "NA"}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box sx={{ display: "flex", gap: "20px" }}>
              <Typography>District : </Typography>
              <Typography color={"grey"}>{data?.district || "NA"}</Typography>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box sx={{ display: "flex", gap: "20px" }}>
              <Typography>Person Name : </Typography>
              <Typography color={"grey"}>
                {data?.family_person_information?.person_name || "NA"}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box sx={{ display: "flex", gap: "20px" }}>
              <Typography>Mail ID : </Typography>
              <Typography color={"grey"}>
                {data?.family_person_information?.email || "NA"}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={4} md={6} lg={4} sm={6}>
            <Box sx={{ display: "flex", gap: "20px" }}>
              <Typography>Phone No : </Typography>
              <Typography color={"grey"}>{data?.phone || "NA"}</Typography>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box sx={{ display: "flex", gap: "20px" }}>
              <Typography>Consanguinity : </Typography>
              <Typography color={"grey"}>
                {data?.family_person_information.consanguinitu || "NA"}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box sx={{ display: "flex", gap: "20px" }}>
              <Typography>Informant : </Typography>
              <Typography color={"grey"}>
                {data?.family_person_information?.informant
                  ? findObjectKeyByValue(
                      data?.family_person_information?.informant,
                      INFORMANT
                    )
                  : "NA"}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box sx={{ display: "flex", gap: "20px" }}>
              <Typography>WeHear Executive Name : </Typography>
              <Typography color={"grey"}>
                {data?.wehear_executive_name || "NA"}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          border: "1px solid",
          borderRadius: "4px",
          marginTop: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            borderBottom: "1px solid",
            borderTopRightRadius: "4px",
            borderTopLeftRadius: "4px",
            p: 2,
            gap: 2,
            backgroundColor: "#EDF0F7",
          }}
        >
          <Typography color={"black"} variant="h4">
            Chief Compliant information
          </Typography>
          <Edit
            onClick={() => {
              let route =
                "/patient-details/" + params.id + "/update/additional";
              dispatch(closeModal("information"));
              navigate(route, { state: { tabData: 1 } });
            }}
            sx={{ cursor: "pointer" }}
            fontSize={theme.typography.h5.fontSize}
            color="black"
          />
        </Box>

        <Box p={2}>
          <Typography color={"primary"} variant="h4">
            1. Please tell us about your hearing concerns:
          </Typography>
          <Typography m={2} color={"grey"} variant="body2">
            {data.problems.please_tell_us_about_your_hearing_concerns || "NA"}
          </Typography>
          <Typography color={"primary"} variant="h4" sx={{ mt: 4 }}>
            2. My hearing is:
          </Typography>
          <Typography m={2} color={"grey"} variant="body2">
            {data.problems.hearing
              ? findObjectKeyByValue(data.problems.hearing, HEARING_PROBLEM)
              : "NA"}
          </Typography>
          <Typography color={"primary"} variant="h4" sx={{ mt: 4 }}>
            3. Do you have difficulty hearing:
          </Typography>
          <Grid container sx={{ p: 1 }}>
            {hearingDifficultyArr.map((option, index) => (
              <Grid key={index}>
                {data?.problems?.hearing_difficulty?.[option] && (
                  <Typography m={2} color={"grey"} variant="body2">
                    {HEARING_DIFFICULTY[index]} ,
                  </Typography>
                )}
              </Grid>
            ))}
          </Grid>
          <Box sx={{ mt: 4 }}>
            <Typography color={"primary"} variant="h4">
              4. Do you have history of:
            </Typography>
            <Grid container sx={{ p: 2 }}>
              {historyArray1.map((option, index) => (
                <Grid container item xs={6} key={index} alignItems="center">
                  <Grid item xs={4}>
                    <Typography color={"primary"} variant="body1">
                      {option.label}:
                    </Typography>
                  </Grid>
                  <Typography m={2} color={"grey"} variant="body2">
                    {findObjectKeyByValue(
                      data?.problems?.hearing_history?.[option.key] || 4,
                      HEARING_HISTORY_PROBLEM
                    )}
                  </Typography>
                </Grid>
              ))}
              {historyArray2.map((option, index) => (
                <Grid item key={index}>
                  <FormControlLabel
                    value={option}
                    control={
                      <Checkbox
                        checked={data?.problems?.hearing_history?.[option.key]}
                        disabled
                      />
                    }
                    label={option.label}
                  />
                </Grid>
              ))}
            </Grid>
          </Box>
          <Typography color={"primary"} variant="h4" sx={{ mt: 4 }}>
            5. Have you had:
          </Typography>
          <Grid container sx={{ p: 2 }}>
            {diseaseArray.map((option, index) => (
              <>
                {data?.problems?.medical_history?.[option.key] && (
                  <Grid item xs={4}>
                    <Typography color={"primary"} variant="body1">
                      {option.label}
                    </Typography>
                  </Grid>
                )}
              </>
            ))}
          </Grid>
          <Typography color={"primary"} variant="h4" sx={{ mt: 4 }}>
            6. Have you had hearing aid:
          </Typography>
          <Typography m={2} color={"grey"} variant="body2">
            side :{" "}
            {data.problems.hearing_aid.side
              ? findObjectKeyByValue(
                  data.problems.hearing_aid.side,
                  HEARING_HISTORY_PROBLEM
                )
              : "NA"}
          </Typography>
          <Typography m={2} color={"grey"} variant="body2">
            Model : {data.problems.hearing_aid.model || "NA"}
          </Typography>
          <Typography color={"primary"} variant="h4" sx={{ mt: 5 }}>
            7. Do you have any family member with hearing loss?
          </Typography>
          <RadioGroup
            sx={{ pl: 2 }}
            value={
              data.problems.family_member_hearing_loss
                .has_family_member_with_hearing_loss
            }
            row
            disabled
          >
            <FormControlLabel
              value={SSA_REGISTERED.YES}
              control={<Radio />}
              label="YES"
            />
            <FormControlLabel
              value={SSA_REGISTERED.NO}
              control={<Radio />}
              label="NO"
            />
          </RadioGroup>
          {data.problems.family_member_hearing_loss
            .has_family_member_with_hearing_loss && (
            <Typography m={2} color={"grey"} variant="body2">
              Who : {data.problems.family_member_hearing_loss.who ?? "NA"}
            </Typography>
          )}
          <Typography color={"primary"} variant="body1" sx={{ p: 2 }}>
            When did the family member lose hearing?
          </Typography>
          <RadioGroup
            sx={{ pl: 2 }}
            value={data.problems.family_member_hearing_loss.when_lost_hearing}
            row
            disabled
          >
            <FormControlLabel
              value={LOSE_HEARING.BIRTH}
              control={<Radio />}
              label="Birth"
            />
            <FormControlLabel
              value={LOSE_HEARING.MID_LIFE}
              control={<Radio />}
              label="Mid-life"
            />
            <FormControlLabel
              value={LOSE_HEARING.LIFE_ONSET}
              control={<Radio />}
              label="Life-onset"
            />
          </RadioGroup>
          <Typography m={2} color={"grey"} variant="body2">
            {data.problems.family_member_hearing_loss.additional_information}
          </Typography>
          <Typography color={"primary"} variant="h4" sx={{ mt: 2 }}>
            Conclusion:
          </Typography>
          <Typography m={2} color={"grey"} variant="body2">
            {data.problems.conclusion || "NA"}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default PatientAdditionalInformationView;
