import React from "react";
import {
  Box,
  Divider,
  FormControlLabel,
  Grid,
  InputAdornment,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Typography,
} from "@mui/material";
import CustomInput from "../../../components/inputs/CustomInput";
import COUNTRY_CODES from "../../../utils/CountryCode";
import { SSA_REGISTERED, INFORMANT } from "../../../utils/constants";

const AdditionalInformation = ({ fields, setFields, loading }) => {
  return (
    <Box>
      <Grid container xs={9} md={9} sm={12}>
        <Grid item xs={12} md={12} sm={12} sx={{ height: "82vh" }}>
          <Grid container spacing={2}>
            <Grid item xs={4} md={4}>
              <CustomInput
                autoFocus={true}
                disabled={loading}
                value={fields?.language || ""}
                onChange={(e) =>
                  setFields({
                    ...fields,

                    language: e.target.value,
                  })
                }
                type="text"
                label="Language"
              />
            </Grid>
            <Grid item xs={4} md={4}>
              <CustomInput
                disabled={loading}
                value={fields?.occupation_standard || ""}
                onChange={(e) =>
                  setFields({
                    ...fields,

                    occupation_standard: e.target.value,
                  })
                }
                type="text"
                label="Occupation/Standard"
              />
            </Grid>
            <Grid item xs={4} md={4}>
              <CustomInput
                disabled={loading}
                value={fields?.aadhar_no || ""}
                onChange={(e) =>
                  setFields({
                    ...fields,

                    aadhar_no: e.target.value,
                  })
                }
                type="text"
                label="Aadhar Card No"
              />
            </Grid>
            <Grid item xs={4} md={4}>
              <Box
                sx={{
                  mt: 2,
                  height: "82%",
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  border: "1px solid black",
                  borderRadius: "4px",
                  justifyContent: "space-evenly",
                }}
              >
                <Box>
                  <Typography variant="body">SSA Registered</Typography>
                </Box>
                <Divider orientation="vertical" flexItem />
                <Box>
                  <RadioGroup
                    value={fields?.ssa_registered || false}
                    onChange={(e) =>
                      setFields({
                        ...fields,
                        additional_details: {
                          ...fields.additional_details,
                          ssa_registered: e.target.value == "true",
                        },
                      })
                    }
                    row
                  >
                    {Object.keys(SSA_REGISTERED).map((item) => (
                      <FormControlLabel
                        key={item}
                        value={SSA_REGISTERED[item]}
                        control={<Radio />}
                        label={item}
                      />
                    ))}
                  </RadioGroup>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={4} md={4}>
              <CustomInput
                disabled={loading}
                value={fields?.disablity_id_no || ""}
                onChange={(e) =>
                  setFields({
                    ...fields,

                    disablity_id_no: e.target.value,
                  })
                }
                type="text"
                label="Disability ID No"
              />
            </Grid>
            <Grid item xs={4}>
              <CustomInput
                disabled={loading}
                value={fields?.district || ""}
                onChange={(e) =>
                  setFields({
                    ...fields,

                    district: e.target.value,
                  })
                }
                type="text"
                label="District"
              />
            </Grid>
            <Grid item xs={6}>
              <CustomInput
                disabled={loading}
                value={fields?.family_person_information?.person_name || ""}
                onChange={(e) =>
                  setFields({
                    ...fields,
                    family_person_information: {
                      ...fields.family_person_information,
                      person_name: e.target.value,
                    },
                  })
                }
                type="text"
                label="Person Name"
              />
            </Grid>
            <Grid item xs={6}>
              <CustomInput
                disabled={loading}
                value={fields?.family_person_information?.email || ""}
                onChange={(e) =>
                  setFields({
                    ...fields,

                    family_person_information: {
                      ...fields.family_person_information,
                      email: e.target.value,
                    },
                  })
                }
                type="text"
                label="Mail ID"
              />
            </Grid>
            <Grid item xs={4} md={6} lg={4} sm={6}>
              <CustomInput
                // InputProps={{
                //   startAdornment: (
                //     <InputAdornment position="start">
                //       <Select
                //         disableUnderline
                //         value={fields?.country_code}
                //         onChange={(e) =>
                //           setFields({
                //             ...fields,
                //             err: "",
                //             country_code: e.target.value,
                //           })
                //         }
                //         variant="standard"
                //         size="small"
                //       >
                //         {COUNTRY_CODES.map((item) => (
                //           <MenuItem key={item.code} value={item.dial_code}>
                //             {item.code}({item.dial_code})
                //           </MenuItem>
                //         ))}
                //       </Select>
                //     </InputAdornment>
                //   ),
                // }}
                disabled={loading}
                value={fields?.phone}
                onChange={(e) =>
                  setFields({
                    ...fields,

                    family_person_information: {
                      ...fields.family_person_information,
                      person_phone_no: e.target.value,
                    },
                  })
                }
                type="number"
                label={"Phone No"}
              />
            </Grid>
            <Grid item xs={2.5} md={6} lg={2.5} sm={6}>
              <CustomInput
                disabled={loading}
                value={fields?.family_person_information?.consanguinitu}
                onChange={(e) =>
                  setFields({
                    ...fields,
                    family_person_information: {
                      ...fields.family_person_information,
                      consanguinitu: e.target.value,
                    },
                  })
                }
                type="text"
                label={"Consanguinity"}
              />
            </Grid>
            <Grid item xs={5.5} md={12} lg={5.5} sm={12}>
              <Box
                sx={{
                  mt: 2,
                  height: "82%",
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  border: "1px solid black",
                  borderRadius: "4px",
                  justifyContent: "space-evenly",
                }}
              >
                <Box>
                  <Typography variant="body">Informant</Typography>
                </Box>

                <Divider orientation="vertical" variant="middle" flexItem />

                <Box>
                  <RadioGroup
                    value={fields?.family_person_information?.informant}
                    onChange={(e, changeValue) =>
                      setFields({
                        ...fields,
                        family_person_information: {
                          ...fields.family_person_information,
                          informant: changeValue,
                        },
                      })
                    }
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                  >
                    {Object.keys(INFORMANT).map((item) => (
                      <FormControlLabel
                        disabled={loading}
                        key={item}
                        value={INFORMANT[item]}
                        control={<Radio />}
                        label={item}
                      />
                    ))}
                  </RadioGroup>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <CustomInput
                disabled={loading}
                value={fields?.wehear_executive_name || ""}
                onChange={(e) =>
                  setFields({
                    ...fields,

                    wehear_executive_name: e.target.value,
                  })
                }
                type="text"
                label="WeHear Executive Name"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AdditionalInformation;
