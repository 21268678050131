export const COMPANY_NAME = "Wehear";

export const companyDetails = {
  NAME: "Wehear",
  EMAIL: "",
  ADDRESS: "",
  GST: "",
  bankName: "",
  bankIFSC: "",
  bankNo: "",
  companyName: COMPANY_NAME,
};
export const THIRD_PART_APPS_USED = {
  none: "none",
};
export const USER_ROLES = {
  ADMIN: 1,
  DISTRIBUTOR: 3,
  AUDIO_LOGIST: 2,
};
export const NOTIFICATION_TYPE = {
  general: "general",
};
export const SNACK_BAR_VARIETNS = {
  error: "error",
  suceess: "success",
  warning: "warning",
};

export const actions = {
  SIGN_IN: "SIGN_IN",
  SET_USER: "SET_USER",
  SIGN_OUT: "SIGN_OUT",
  OPEN_MODAL: "OPEN_MODAL",
  CLOSE_MODAL: "CLOSE_MODAL",

  SET_NOTIFICATION_DATA: "SET_NOTIFICATION_DATA",
  NOTIFICATION_READ: "NOTIFICATION_READ",

  CALL_SNACKBAR_DATA: "CALL_SNACKBAR_DATA",

  CHNAGE_DEVICE_SIDE_SELECTION: "CHNAGE_DEVICE_SIDE_SELECTION",
  CHANGE_DEVICE_SELECTION: "CHNAGE_DEVICE_SELECTION",
  CHNAGE_REMARKS: "CHNAGE_REMARKS",
  CHNAGE_LOADING_VALUES: "CHNAGE_LOADING_VALUES",
  CHNAGE_DEVICE_COMPATIBLITY: "CHNAGE_DEVICE_COMPATIBLITY",
  CHANGE_WRITE_FUN: "CHANGE_WRITE_FUN",
  CONNECT_DEVICE: "CONNECT_DEVICE",
  REMOTE_FITTING: "REMOTE_FITTING",
  DISCONNECT_DEVICE: "DISCONNECT_DEVICE",
  CHANGE_BYTE_VAL: "CHANGE_BYTE_VAL",
  CHANGE_BYTE_ARRAY_VAL: "CHANGE_BYTE_ARRAY_VAL",
  CHANGE_MASTER_GAIN: "CHANGE_MASTER_GAIN",
  CHANGE_COMPRESSION: "CHANGE_COMPRESSION",
  CHANGE_WIND_NOISE_REDUCTION: "CHANGE_WIND_NOISE_REDUCTION",
  CHANGE_NOISE_REDUCTION: "CHANGE_NOISE_REDUCTION",
  CHANGE_SHARPNESS: "CHANGE_SHARPNESS",
  CHANGE_SPEECH_REDUCTION: "CHANGE_SPEECH_REDUCTION",
  READ_FROM_DEVICE: "READ_FROM_DEVICE",
  CHANGE_PROFILE_ID: "CHANGE_PROFILE_ID",
  SET_AUTO_FIT_DATA: "SET_AUTO_FIT_DATA",
  RESET_DEVICE: "RESET_DEVICE",
  CHANGE_FITTING_DATA: "CHANGE_FITTING_DATA",
  CHANGE_MULTIPLE_BYTE_VAL: "CHANGE_MULTIPLE_BYTE_VAL",
  MUTED_ACTION: "MUTED_ACTION",
  CHANGE_MODE: "CHANGE_MODE",
  RESET_FITTING_REDUCER: "RESET_FITTING_REDUCER",
  SET_MODE_DATA: "SET_MODE_DATA",
};

export const DATE_TIME_FILTERS = {
  this_week: "This Week",
  this_month: "This Month",
  this_year: "This Year",
  last_month: "Last Month",
  last_year: "Last Year",
  last_week: "Last Week",
  today: "Today",
  yesterday: "Yesterday",
};

export const REGIONS_TYPE = {
  COUNTRY: 1,
  STATE: 2,
  DISTRICT: 3,
  TALUKA: 4,
};

export const GENDER = {
  MALE: 1,
  FEMALE: 2,
  OTHER: 3,
};

export const SSA_REGISTERED = {
  YES: true,
  NO: false,
};

export const INFORMANT = {
  SELF: 1,
  "FATHER/MOTHER": 2,
  OTHER: 3,
};

export const WDRC = {
  MAX_LINEAR_GAIN: 80,
  MIN_LINEAR_GAIN: 0,
  MAX_EXPANSION_KNEE: 130,
  MIN_EXPANSION_KNEE: 0,
  MAX_COMPRESSION_RATIO: 4 * 10,
  MIN_COMPRESSION_RATIO: 0,
};

export const LOSE_HEARING = {
  BIRTH: 1,
  MID_LIFE: 2,
  LIFE_ONSET: 3,
};

export const HEARING_DIFFICULTY = {
  0: "The Noisy Place",
  1: "In Quiet Place",
  2: "In Restaurant",
  3: "The Television",
  4: "Over the Telephone",
  5: "One on One Talking",
};
export const HEARING_PROBLEM = {
  "BETTER IN RIGHT EAR": 1,
  "BETTER IN LEFT EAR": 2,
  "ABOUT SAME IN BOTH EAR": 3,
  UNSURE: 4,
};
export const HEARING_HISTORY_PROBLEM = {
  RIGHT: 1,
  LEFT: 2,
  BOTH: 3,
  NO: 4,
};
