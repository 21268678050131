import React from "react";
import CustomDialog from "../../components/layouts/common/CustomDialog";
import { Box, Tab, Tabs } from "@mui/material";
import { PatientInformationView } from "./PatientInformationView";
import PatientAdditionalInformationView from "./PatientAdditionalInformationView";

export const PatientInformationViewController = ({ data }) => {
  const [tab, setTab] = React.useState(0);
  return (
    <>
      <CustomDialog
        id={"information"}
        closeText="Close"
        title={
          <Box sx={{ display: "flex", p: 0 }}>
            <Tabs
              value={tab}
              size="Small"
              onChange={(e, newVal) => setTab(newVal)}
            >
              <Tab value={0} size="small" label="Patient Information" />
              <Tab value={1} size="small" label="Additional Information" />
            </Tabs>
          </Box>
        }
      >
        {tab == 0 && <PatientInformationView data={data} />}
        {tab == 1 && <PatientAdditionalInformationView data={data} />}
      </CustomDialog>
    </>
  );
};
