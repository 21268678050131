import {
  PermIdentityOutlined,
  DriveFileRenameOutlineOutlined,
  Earbuds,
  Edit,
  Person,
  Visibility,
} from "@mui/icons-material";
import NoDataComponent from "../../components/layouts/NoDataComponent";
import SettingsRemoteIcon from "@mui/icons-material/SettingsRemote";
import AddIcon from "@mui/icons-material/Add";
import mcl_left from "../../assets/images/mcl_left.svg";
import mcl_right from "../../assets/images/mcl_right.svg";
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Paper,
  Radio,
  RadioGroup,
  Stack,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import { CenteredBox } from "../../components/layouts/OneViewBox";
import {
  DEVICES,
  DEVICES_NAME,
  DEVICES_TUBE_NAME,
  DOM_TYPE_NAME,
  LISTENING_SIDE,
} from "../../utils/patient.constants";
import HearingAidIcon from "../../assets/icons/HearingAidIcon";
import { useState } from "react";
import moment from "moment";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FlexedColumnBox } from "../../components/layouts/common/boxes";
import { closeModal, openModal } from "../../store/actions/modalAction";
import CustomDialog from "../../components/layouts/common/CustomDialog";
import { Line } from "react-chartjs-2";
import LEFTIMG from "../../assets/images/Left (1).png";
import RIGHTIMG from "../../assets/images/Left (2).png";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import LEFTARROWICON from "../../assets/images/leftIcon.svg";
import RIGHTARROWICON from "../../assets/images/rightarrow.svg";
import LetterAIcon from "../../assets/images/letter-a.svg";
import ucl_left from "../../assets/images/ucl_left.svg";
import ucl_right from "../../assets/images/ucl_right.svg";
import ReassignComponent from "./ReassignComponent";
import connectIcon1 from "../../assets/images/connectIcon(1).svg";
import connectIcon2 from "../../assets/images/connectIcon(2).svg";
import connectIcon4 from "../../assets/images/connectIcon(4).svg";
import CoonectDeviceModule from "../../components/bluetooth/CoonectDeviceModule";
import {
  applyForRemoteFitting,
  changeDeviceCompatiblity,
  changeLoadinfMessages,
  connectDevice,
  disconnectDevice,
  onWriteFunctionChange,
} from "../../store/actions/fittingAction";
import { PatientInformationView } from "./PatientInformationView";
import { PatientInformationViewController } from "./PatientInformationViewController";

const OuterContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flex: 1,
  overflow: "hidden",
  borderTop: "1px solid #DDDDDD",
  background: theme.palette.light.main,
}));

const ContentContainer = styled(Box)(
  ({ theme, overflow = "auto", noPadding }) => ({
    display: "flex",
    flexDirection: "column",
    flex: 1,
    padding: noPadding ? 0 : theme.spacing(2) + " " + theme.spacing(3),
    width: "100%",
    height: "78%",
    overflowY: overflow,
    borderRadius: theme.shape.borderRadius,
  })
);

const HistoryUpdate = ({ historyData, data }) => {
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();

  const [second, setSecond] = useState(false);
  const [secondId, setSecondId] = useState(null);

  return (
    <>
      <CustomDialog
        id={"updateHistory"}
        closeText="Close"
        title={"Select device for configuration"}
      >
        {second && (
          <Box
            mt={3}
            mb={3}
            sx={{ border: "1px #E8E8E8 solid", borderRadius: "5px" }}
            width={"100%"}
            height={"50vh"}
          >
            <Grid
              p={1}
              container
              sx={{
                backgroundColor: "#E8E8E8",
              }}
            >
              <Grid item xs={4.5}>
                <Typography ml={3} variant="h6" sx={{ color: "black" }}>
                  Date and Time
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography variant="h6" sx={{ color: "black" }}>
                  Device Name
                </Typography>
              </Grid>
            </Grid>

            <ContentContainer sx={{ height: "90%" }} noPadding overflow="auto">
              {data?.profiles?.filter(
                (item) => item.device_side != historyData.side
              ).length != 0 ? (
                <FormControl sx={{ marginLeft: "5px" }}>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    name="radio-buttons-group"
                  >
                    {data?.profiles
                      ?.filter((item) => item.device_side != historyData.side)
                      .map((item, index) => (
                        <FormControlLabel
                          onChange={(e) => {
                            setSecondId(e.target.value);
                          }}
                          sx={{ borderBottom: "1px solid #E8E8E8" }}
                          value={item._id}
                          control={<Radio />}
                          label={
                            <Box width={"30vw"}>
                              <HistoryBox
                                isUpdate={true}
                                key={item._id}
                                patientData={data}
                                even={index % 2 === 0}
                                {...item}
                              />
                            </Box>
                          }
                        />
                      ))}
                  </RadioGroup>
                </FormControl>
              ) : (
                <NoDataComponent message={"No Profile Found"} Icon={Earbuds} />
              )}
            </ContentContainer>
          </Box>
        )}

        {!second && (
          <Box
            sx={{
              display: "flex",
              height: "50vh",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <Typography
              color={"black"}
              variant="h6"
              fontSize={"30px"}
              gutterBottom
            >
              Do you also want to edit the configuration of the
            </Typography>
            <Box display={"flex"} flexDirection={"row"} gap={"3px"}>
              <Typography
                color={
                  historyData.side != LISTENING_SIDE.LEFT
                    ? "#1265A1"
                    : "#C24747"
                }
                variant="h6"
                fontSize={"30px"}
              >
                {historyData.side == LISTENING_SIDE.LEFT ? "RIGHT " : "LEFT "}
              </Typography>
              <Typography color={"black"} variant="h6" fontSize={"30px"}>
                hearing Aid ?
              </Typography>
            </Box>
          </Box>
        )}
        <Box mt={3}>
          <Button
            onClick={(e) => {
              e.preventDefault();
              navigate(params.id + "/" + historyData._id + "/fitting");
              dispatch(closeModal("updateHistory"));
            }}
            sx={{ width: "48%", marginRight: "2%" }}
            variant="outlined"
          >
            No
          </Button>
          <Button
            disabled={second && !secondId}
            onClick={(e) => {
              if (second) {
                e.preventDefault();
                navigate(
                  params.id +
                    "/" +
                    historyData._id +
                    "/" +
                    secondId +
                    "/fitting"
                );
                dispatch(closeModal("updateHistory"));
              } else {
                setSecond(true);
              }
            }}
            sx={{ width: "48%", marginLeft: "2%" }}
            variant="contained"
          >
            {second ? "Configure" : "YES"}
          </Button>
        </Box>
      </CustomDialog>
    </>
  );
};

const ChartComponent = ({ rightEar, dataB, dataA, dataC, dataD }) => {
  const boneImg = new Image();
  if (rightEar) {
    boneImg.src = RIGHTARROWICON;
  } else {
    boneImg.src = LEFTARROWICON;
  }
  boneImg.height = 8;
  boneImg.width = 4;
  const aidedImg = new Image();
  aidedImg.src = LetterAIcon;

  const UclImg = new Image();
  if (rightEar) {
    UclImg.src = ucl_right;
  } else {
    UclImg.src = ucl_left;
  }
  UclImg.height = 16;
  UclImg.width = 16;

  const MclImg = new Image();
  if (rightEar) {
    MclImg.src = mcl_right;
  } else {
    MclImg.src = mcl_left;
  }
  MclImg.height = 16;
  MclImg.width = 16;

  const data = {
    labels: ["", "250", "500", "1k", "2k", "4k", "8k"],
    datasets: [
      {
        label: "Fitting Range AC",
        data: dataA?.map((item) => item),
        borderColor: "#C24747",
        pointBackgroundColor: "white",
        pointStyle: rightEar ? "circle" : "crossRot",
        hoverBorderColor: rightEar ? "#E62323" : "#1265A1",
        borderColor: rightEar ? "#E62323" : "#1265A1",
        radius: rightEar ? 6 : 7,
      },
      {
        label: "Fitting Target BC",
        data: dataB?.map((item) => item),
        pointBackgroundColor: "white",
        pointStyle: boneImg,
        hoverBorderColor: rightEar ? "#E62323" : "#1265A1",
        borderColor: rightEar ? "#E62323" : "#1265A1",
        radius: rightEar ? 4 : 7,
        hoverRadius: rightEar ? 4 : 7,
        borderWidth: rightEar ? 1 : 1,
        hoverBorderWidth: rightEar ? 1 : 1,
      },
      {
        label: "Fitting Target UCL",
        data: dataC?.map((item) => item),
        pointBackgroundColor: "white",
        pointStyle: UclImg,
        hoverBorderColor: rightEar ? "#E62323" : "#1265A1",
        borderColor: rightEar ? "#E62323" : "#1265A1",
        radius: rightEar ? 4 : 7,
        hoverRadius: rightEar ? 4 : 7,
        borderWidth: rightEar ? 1 : 1,
        hoverBorderWidth: rightEar ? 1 : 1,
      },
      {
        label: "Fitting Target MCL",
        data: dataD?.map((item) => item),
        pointBackgroundColor: "white",
        pointStyle: MclImg,
        hoverBorderColor: rightEar ? "#E62323" : "#1265A1",
        borderColor: rightEar ? "#E62323" : "#1265A1",
        radius: rightEar ? 4 : 7,
        hoverRadius: rightEar ? 4 : 7,
        borderWidth: rightEar ? 1 : 1,
        hoverBorderWidth: rightEar ? 1 : 1,
      },
      {
        label: "Grey Area Upper Bound",
        data: [70, 70, 70, 75, 85, 85, 85, 85],
        borderColor: "rgba(0, 0, 0, 0)",
        backgroundColor: "rgba(200, 200, 200, 0.5)",
        pointRadius: 0,
        fill: "+1",
      },
      {
        label: "Grey Area Lower Bound",
        data: [10, 10, 10, 20, 30, 30, 30, 30],
        borderColor: "rgba(0, 0, 0, 0)",
        backgroundColor: "rgba(200, 200, 200, 0)",
        pointRadius: 0,
        fill: "origin",
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        grid: {
          display: true,
        },
        ticks: {
          font: {
            size: 10,
            color: "#666666", // Font size for x-axis labels
          },
          // Padding to move the labels outside the box
        },
      },
      y: {
        min: -10,
        max: 120,
        reverse: true,
        ticks: {
          callback: function (value) {
            return value;
          },
          stepSize: 10,
          autoSkip: false,
          font: {
            size: 8,
          },
        },
        grid: {
          display: true,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
      datalabels: {
        display: false,
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    responsive: true,
    animation: false,
    elements: {
      line: {
        borderWidth: 1,
        // tension: 0.4,
      },
    },
  };

  return (
    <Box sx={{ minHeight: "100%", maxWidth: "100%" }}>
      <Line data={data} options={options} />
    </Box>
  );
};

const HistoryBox = ({ even, patientData, isUpdate, ...data }) => {
  const { user } = useSelector((state) => state);
  const { fitting, fittingLeft } = useSelector((state) => state);

  const dispatch = useDispatch();
  const message = `Please Open Wehear App,

And Add following details in remote fitting section:
Patient Id: ${patientData?.patient_uid}
Profile Id: ${data?._id}

Message By,
${user.data.name}
    `;

  return (
    <Box
      sx={{
        flexDirection: "column",
        justifyContent: "flex-start",
        textAlign: "start",
        alignItems: "flex-start",
        alignContent: "flex-start",
        borderBottomColor: "divider",
        background: isUpdate ? "light" : even ? "light" : "#F5F5F5",
      }}
    >
      <Box pt={2} pl={3} pr={3}>
        <Grid container xs={12} spacing={0}>
          <Grid item xs={4}>
            <Stack direction={"row"} spacing={2}>
              <Typography variant="h6" fontWeight={500} color={"#4D4D4D"}>
                {moment(data.updatedAt).format("DD/MM/YYYY")}
              </Typography>
              <Typography fontWeight={400} color={"#4D4D4D"} variant="h6">
                {moment(data.updatedAt).format("HH:mm")}
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={4}>
            <Stack direction={"row"} spacing={2}>
              <Typography
                variant="h6"
                mb={1}
                fontWeight={500}
                color={"#4D4D4D"}
              >
                {DEVICES_NAME[data.device_type]}
              </Typography>
              <Typography variant="h6" fontWeight={500} color={"#808080"}>
                {data.device_name}
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={4}>
            {!isUpdate && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  borderRadius: "100px",
                }}
                mt={2}
                mb={1}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                  p={2}
                  pt={0}
                  pb={0}
                >
                  <IconButton
                    // disabled={
                    //   data.device_side == LISTENING_SIDE.LEFT
                    //     ? fittingLeft.connected
                    //       ? false
                    //       : true
                    //     : fitting.connected
                    //     ? false
                    //     : true
                    // }

                    onClick={() => {
                      dispatch(
                        openModal(
                          <HistoryUpdate
                            historyData={{
                              _id: data._id,
                              side: data.device_side,
                            }}
                            data={patientData}
                          />,
                          "sm",
                          false,
                          "updateHistory"
                        )
                      );
                    }}
                    // sx={{ padding: 0 }}
                    // LinkComponent={Link}
                    // to={data._id + "/fitting"}
                  >
                    <Edit sx={{ color: "#2D3B67" }} color="info" />
                  </IconButton>
                  <IconButton
                    sx={{ padding: 0, ml: 2 }}
                    LinkComponent={Link}
                    to={data._id + "/fitting" + "/view"}
                  >
                    <Visibility sx={{ color: "#2D3B67" }} color="info" />
                  </IconButton>
                  {/* https://wa.me/+91${patientData?.phone}?text=${encodeURI(message)} */}
                  {/* <IconButton sx={{ padding: 0, ml: 2 }} LinkComponent={'a'} target="_blank" href={https://web.whatsapp.com/send?phone=${patientData?.country_code??"+91"}${patientData?.phone}&text=${encodeURI(message)}} >
                        <WhatsApp color="success" />
                    </IconButton> */}
                </Box>
              </Box>
            )}
          </Grid>
        </Grid>
        <Box sx={{ display: "flex" }}>
          <Typography variant="h6" fontWeight={500} color={"#4D4D4D"}>
            Remarks:
          </Typography>
          <Typography
            ml={1}
            sx={{ display: "flex", flex: 1 }}
            variant="h6"
            fontWeight={500}
            color={"#808080"}
          >
            {data.remarks && data.remarks != "" ? data.remarks : "NA"}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

const PatientAudioGramView = ({ data, onEdit }) => {
  const params = useParams();
  const navigate = useNavigate();
  const { fitting } = useSelector((state) => state);
  const { fittingLeft } = useSelector((state) => state);
  const dispatch = useDispatch();

  const ConnectButton = ({
    loading,
    connected,
    onClick,
    disconnect,
    deviceSide,
  }) => {
    if (!connected) {
      return (
        <Button
          onClick={onClick}
          loading={loading}
          sx={{
            height: "60px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 1,
            border: "2px solid",
            borderColor: "#DDDDDD",
            borderRadius: "8px",
            width: "80%",
            marginLeft: deviceSide == LISTENING_SIDE.LEFT ? "20%" : "",
            marginTop: "5px",
          }}
        >
          {!loading ? (
            <>
              <img src={connectIcon1} alt="ConnectIcon(1)" />
              <Typography
                variant="h5"
                sx={{
                  fontFamily: "League spartan",
                  padding: "5px",
                }}
              >
                CONNECT
              </Typography>
            </>
          ) : (
            <CircularProgress size={25} />
          )}
        </Button>
      );
    } else {
      return (
        <Button
          onClick={() => {
            dispatch(disconnectDevice(deviceSide));
          }}
          loading={loading}
          sx={{
            height: "60px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            border: "2px solid",
            borderColor:
              deviceSide == LISTENING_SIDE.LEFT ? "#2D3B67" : "#C24747",
            borderRadius: "8px",
            width: "80%",
            marginLeft: deviceSide == LISTENING_SIDE.LEFT ? "20%" : "",
            backgroundColor:
              deviceSide == LISTENING_SIDE.LEFT ? "#EDF0F7" : "#FCF7F7",
            marginTop: "5px",
          }}
        >
          {" "}
          <>
            {" "}
            <img src={connectIcon4} alt="ConnectIcon(3)" />
            <Typography
              variant="h5"
              sx={{
                fontFamily: "League spartan",
                padding: "5px",
                color: "#2D3B67",
              }}
            >
              CONNECTED
            </Typography>
          </>
        </Button>
      );
    }
  };

  return (
    <>
      <Grid md={12} sm={12} container display={"flex"}>
        <Grid
          container
          md={6}
          sm={6}
          mt={3}
          flexDirection="columns"
          sx={{
            display: "flex",
            maxHeight: "40%",
            maxWidth: "100%",
            justifyContent: "space-evenly",
            alignItems: "center",
          }}
        >
          <Grid sm={12} md={12} container>
            {!data?.right_fitted_device?.selected ? (
              <Grid item md={4} sm={4} display="flex">
                <Avatar
                  sx={{
                    bgcolor: "#C24747",
                    top: "-15px",
                    width: 24,
                    height: 24,
                    radius: 51,
                    padding: 2,
                    fontSize: 8,
                    m: 1,
                  }}
                >
                  R
                </Avatar>
                <Box height="100%" width="100%">
                  <Button
                    onClick={() => {
                      navigate(
                        `/patient-details/${params.id}/device-selection`,
                        { state: LISTENING_SIDE.RIGHT }
                      );
                    }}
                    sx={{
                      height: "50vh",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      border: "2px dashed",
                      borderColor: "#C24747",
                      width: "80%",
                      backgroundColor: "#FCF7F7",
                    }}
                  >
                    <IconButton>
                      <AddIcon />
                    </IconButton>
                    <Typography sx={{ color: "#4D4D4D", fontSize: "20px" }}>
                      Select Device
                    </Typography>
                  </Button>

                  <Button
                    disabled={true}
                    sx={{
                      height: "60px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      zIndex: 1,
                      border: "2px solid",
                      borderColor: "#DDDDDD",
                      borderRadius: "8px",
                      width: "80%",
                      marginTop: "5px",
                    }}
                  >
                    <img src={connectIcon2} alt="ConnectIcon(2)" />
                    <Typography
                      variant="h5"
                      sx={{ fontFamily: "League spartan", padding: "5px" }}
                    >
                      CONNECT
                    </Typography>
                  </Button>
                </Box>
              </Grid>
            ) : (
              <Grid item md={4} sm={4} display={"flex"}>
                <Avatar
                  sx={{
                    bgcolor: "#C24747",

                    top: "-15px",
                    width: 24,
                    height: 24,
                    radius: 51,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: 2,
                    fontSize: 8,
                    m: 1,
                  }}
                >
                  R
                </Avatar>
                <Box
                  height={"100%"}
                  width={"100%"}
                  sx={{
                    overflow: "auto",
                    scrollbarWidth: "none",
                  }}
                >
                  <Paper
                    sx={{
                      height: "50vh",
                      display: "flex",
                      zIndex: 1,

                      border: "2px solid",
                      borderColor: "#C24747",
                      borderRadius: "8px",

                      width: "80%",
                      backgroundColor: "#FCF7F7",
                    }}
                  >
                    <Grid xs={12} display={"flex"} flexDirection={"column"}>
                      <Grid
                        mt={1}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "16px",
                            fontFamily: "League spartan",
                            fontWeight: "bold",
                            alignItems: "center",
                            justifyContent: "center",
                            color: "#C24747",
                          }}
                        >
                          {data?.right_fitted_device?.device_type ===
                          DEVICES.BTE_OPTIMA
                            ? "BTE OPTIMA"
                            : "BTE PRIME"}
                        </Typography>
                      </Grid>
                      <Grid
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "10px",
                            fontFamily: "League spartan",
                            color: "#808080",
                          }}
                        >
                          02567(Serial Number)
                        </Typography>
                      </Grid>
                      <Grid
                        mt={1}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <img
                          src={RIGHTIMG}
                          alt="RightEar"
                          style={{
                            display: "flex",
                            minWidth: "8vw",
                            maxHeight: "150px",
                          }}
                        />
                      </Grid>

                      <Box overflow={"auto"} sx={{ scrollbarWidth: "none" }}>
                        <Grid
                          container
                          p={1}
                          mt={3}
                          className={"main"}
                          display={"flex"}
                          flexDirection={"row"}
                          justifyContent={"space-between"}
                        >
                          <Grid sx={{ color: "#808080" }}>
                            <Typography
                              sx={{
                                fontSize: "12px",
                                fontWeight: "bold",
                                fontFamily: "League spartan",
                              }}
                            >
                              Tube
                            </Typography>
                          </Grid>
                          <Grid ml={2} sx={{ color: "#808080" }}>
                            <Typography
                              sx={{
                                fontSize: "12px",
                                fontWeight: "bold",
                                fontFamily: "League spartan",
                              }}
                            >
                              Dome
                            </Typography>
                          </Grid>
                          <Grid sx={{ color: "#808080" }}>
                            <Typography
                              sx={{
                                fontSize: "12px",
                                fontWeight: "bold",
                                fontFamily: "League spartan",
                              }}
                            >
                              Channel
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          p={1}
                          mt={-3}
                          display={"flex"}
                          flexDirection={"row"}
                          justifyContent={"space-between"}
                        >
                          <Grid sx={{ color: "#4D4D4D" }}>
                            <Typography
                              sx={{
                                fontSize: "12px",
                                fontFamily: "League spartan",
                              }}
                            >
                              {
                                DEVICES_TUBE_NAME[
                                  data?.right_fitted_device?.device_tube
                                ]
                              }
                            </Typography>
                          </Grid>
                          <Grid ml={-4} sx={{ color: "#4D4D4D" }}>
                            <Typography
                              sx={{
                                fontSize: "12px",
                                fontFamily: "League spartan",
                              }}
                            >
                              {
                                DOM_TYPE_NAME[
                                  data?.right_fitted_device?.dom_type
                                ]
                              }
                            </Typography>
                          </Grid>
                          <Grid mr={6} sx={{ color: "#4D4D4D" }}>
                            <Typography
                              sx={{
                                fontSize: "12px",
                                fontFamily: "League spartan",
                              }}
                            >
                              {data?.right_fitted_device?.device_channel}
                            </Typography>
                          </Grid>
                        </Grid>

                        <Grid
                          mt={1}
                          xs={12}
                          sm={12}
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Button
                            onClick={() => {
                              navigate(
                                `/patient-details/${params.id}/device-selection`,
                                { state: LISTENING_SIDE.RIGHT }
                              );
                            }}
                            sx={{
                              fontSize: "20px",
                              textDecoration: "underline",
                            }}
                          >
                            Change
                          </Button>
                        </Grid>
                      </Box>
                    </Grid>
                  </Paper>

                  <CoonectDeviceModule
                    onConnectWithDevice={(data, deviceInfo, disconnectFun) => {
                      dispatch(
                        connectDevice(
                          data,
                          deviceInfo,
                          disconnectFun,
                          fitting.device_side
                        )
                      );
                    }}
                    Component={ConnectButton}
                    onLoadingChange={(loader, message) => {
                      dispatch(
                        changeLoadinfMessages(
                          loader,
                          message,
                          fitting.device_side
                        )
                      );
                    }}
                    onEnableChange={(val) =>
                      dispatch(
                        changeDeviceCompatiblity(val, fitting.device_side)
                      )
                    }
                    onWriteFunctionEnabled={(fun) =>
                      dispatch(onWriteFunctionChange(fun, fitting.device_side))
                    }
                    onDisconnect={() =>
                      dispatch(disconnectDevice(fitting.device_side))
                    }
                    fitting={fitting}
                  />
                </Box>
              </Grid>
            )}
            <Grid
              md={8}
              sm={8}
              sx={{
                maxHeight: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  color: "#808080",
                  fontSize: "16px",
                }}
              >
                Audiogram{" "}
                <IconButton
                  onClick={(e) => {
                    onEdit(e, true);
                  }}
                  sx={{ padding: 0, ml: 2 }}
                >
                  <DriveFileRenameOutlineOutlined
                    fontSize="inherit"
                    color="#DDDDDD"
                  />
                </IconButton>
              </Typography>
              <Box height={"55vh"}>
                <ChartComponent
                  rightEar
                  dataA={[
                    data?.rf1,
                    data?.rf2,
                    data?.rf3,
                    data?.rf4,
                    data?.rf5,
                    data?.rf6,
                    data?.rf7,
                    data?.rf8,
                  ]}
                  dataB={[
                    data?.brf1,
                    data?.brf2,
                    data?.brf3,
                    data?.brf4,
                    data?.brf5,
                    data?.brf6,
                    data?.brf7,
                    data?.brf8,
                  ]}
                  dataC={[
                    data?.urf1,
                    data?.urf2,
                    data?.urf3,
                    data?.urf4,
                    data?.urf5,
                    data?.urf6,
                    data?.urf7,
                    data?.urf8,
                  ]}
                  dataD={[
                    data?.mrf1,
                    data?.mrf2,
                    data?.mrf3,
                    data?.mrf4,
                    data?.mrf5,
                    data?.mrf6,
                    data?.mrf7,
                    data?.mrf8,
                  ]}
                />
              </Box>
            </Grid>
          </Grid>
          <Box
            ml={1}
            sx={{
              height: "26vh",
              flex: 1,
            }}
          >
            <Typography variant="subtitle1" m={3} sx={{ color: "black" }}>
              Fitting History
            </Typography>
            <Box
              sx={{
                borderRadius: "4px",
                border: "1px solid #DDDDDD",
                height: "100%",
                overflow: "hidden",
              }}
            >
              <Grid
                p={1}
                container
                sx={{
                  backgroundColor: "#E8E8E8",
                }}
              >
                <Grid item xs={4.5}>
                  <Typography ml={3} variant="h6" sx={{ color: "black" }}>
                    Date and Time
                  </Typography>
                </Grid>
                <Grid item xs={5}>
                  <Typography variant="h6" sx={{ color: "black" }}>
                    Device Name
                  </Typography>
                </Grid>
              </Grid>

              <ContentContainer noPadding overflow="auto">
                {data?.profiles?.filter(
                  (item) => item.device_side === LISTENING_SIDE.RIGHT
                ).length != 0 ? (
                  data?.profiles
                    ?.filter(
                      (item) => item.device_side === LISTENING_SIDE.RIGHT
                    )
                    .map((item, index) => (
                      <HistoryBox
                        key={item._id}
                        patientData={data}
                        even={index % 2 === 0}
                        {...item}
                      />
                    ))
                ) : (
                  <NoDataComponent
                    message={"No Profile Found"}
                    Icon={Earbuds}
                  />
                )}
              </ContentContainer>
            </Box>
          </Box>
        </Grid>
        <Grid
          container
          md={6}
          sm={6}
          mt={3}
          flexDirection="columns"
          sx={{
            display: "flex",
            maxHeight: "40%",
            maxWidth: "100%",
            justifyContent: "space-evenly",
            alignItems: "center",
          }}
        >
          <Grid md={12} sm={12} container>
            <Grid
              md={8}
              sm={8}
              sx={{
                maxHeight: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  color: "#808080",
                  fontSize: "16px",
                }}
              >
                Audiogram{" "}
                <IconButton
                  onClick={(e) => {
                    onEdit(e, true);
                  }}
                  sx={{ padding: 0, ml: 2 }}
                >
                  <DriveFileRenameOutlineOutlined
                    fontSize="inherit"
                    color="#DDDDDD"
                  />
                </IconButton>
              </Typography>
              <Box height={"55vh"}>
                <ChartComponent
                  dataA={[
                    data?.lf1,
                    data?.lf2,
                    data?.lf3,
                    data?.lf4,
                    data?.lf5,
                    data?.lf6,
                    data?.lf7,
                    data?.lf8,
                  ]}
                  dataB={[
                    data?.blf1,
                    data?.blf2,
                    data?.blf3,
                    data?.blf4,
                    data?.blf5,
                    data?.blf6,
                    data?.blf7,
                    data?.blf8,
                  ]}
                  dataC={[
                    data?.ulf1,
                    data?.ulf2,
                    data?.ulf3,
                    data?.ulf4,
                    data?.ulf5,
                    data?.ulf6,
                    data?.ulf7,
                    data?.ulf8,
                  ]}
                  dataD={[
                    data?.mlf1,
                    data?.mlf2,
                    data?.mlf3,
                    data?.mlf4,
                    data?.mlf5,
                    data?.mlf6,
                    data?.mlf7,
                    data?.mlf8,
                  ]}
                />
              </Box>
            </Grid>

            {!data?.left_fitted_device?.selected ? (
              <Grid item md={4} sm={4} display="flex">
                <Box height="100%" width="100%">
                  <Button
                    onClick={() => {
                      navigate(
                        `/patient-details/${params.id}/device-selection`,
                        { state: LISTENING_SIDE.LEFT }
                      );
                    }}
                    sx={{
                      height: "50vh",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      border: "2px dashed",
                      borderColor: "#1265A1",
                      marginLeft: "20%",
                      width: "80%",
                      backgroundColor: "#F6FBFE",
                    }}
                  >
                    <IconButton>
                      <AddIcon />
                    </IconButton>
                    <Typography sx={{ color: "#4D4D4D", fontSize: "20px" }}>
                      Select Device
                    </Typography>
                  </Button>

                  <Button
                    disabled={true}
                    sx={{
                      height: "60px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      zIndex: 1,
                      border: "2px solid",
                      borderColor: "#DDDDDD",
                      borderRadius: "8px",
                      width: "80%",
                      marginLeft: "20%",
                      marginTop: "5px",
                    }}
                  >
                    <img src={connectIcon2} alt="ConnectIcon(2)" />
                    <Typography
                      variant="h5"
                      sx={{ fontFamily: "League spartan", padding: "5px" }}
                    >
                      CONNECT
                    </Typography>
                  </Button>
                </Box>
                <Avatar
                  sx={{
                    bgcolor: "#1265A1",
                    top: "-15px",
                    width: 24,
                    height: 24,
                    radius: 51,
                    padding: 2,
                    fontSize: 8,
                    m: 1,
                  }}
                >
                  L
                </Avatar>
              </Grid>
            ) : (
              <Grid item md={4} sm={4} display={"flex"}>
                <Box
                  height={"100%"}
                  width={"100%"}
                  sx={{
                    overflow: "auto",
                    scrollbarWidth: "none",
                  }}
                >
                  <Paper
                    sx={{
                      height: "50vh",
                      display: "flex",
                      zIndex: 1,

                      border: "2px solid",
                      borderColor: "#1265A1",
                      borderRadius: "8px",
                      marginLeft: "20%",
                      width: "80%",
                      backgroundColor: "#F6FBFE",
                    }}
                  >
                    <Grid xs={12} display={"flex"} flexDirection={"column"}>
                      <Grid
                        mt={1}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "16px",
                            fontFamily: "League spartan",
                            fontWeight: "bold",
                            alignItems: "center",
                            justifyContent: "center",
                            color: "#1265A1",
                          }}
                        >
                          {data?.left_fitted_device?.device_type ===
                          DEVICES.BTE_OPTIMA
                            ? "BTE OPTIMA"
                            : "BTE PRIME"}
                        </Typography>
                      </Grid>
                      <Grid
                        mt={1}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "10px",
                            fontFamily: "League spartan",
                            fontSize: "10px",
                            color: "#808080",
                          }}
                        >
                          02567(Serial Number)
                        </Typography>
                      </Grid>
                      <Grid
                        mt={1}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <img
                          src={LEFTIMG}
                          alt="RightEar"
                          style={{
                            display: "flex",
                            minWidth: "8vw",
                            maxHeight: "150px",
                          }}
                        />
                      </Grid>

                      <Box overflow={"auto"} sx={{ scrollbarWidth: "none" }}>
                        <Grid
                          container
                          p={1}
                          mt={3}
                          className={"main"}
                          display={"flex"}
                          flexDirection={"row"}
                          justifyContent={"space-between"}
                        >
                          <Grid sx={{ color: "#808080" }}>
                            <Typography
                              sx={{
                                fontSize: "12px",
                                fontWeight: "bold",
                                fontFamily: "League spartan",
                              }}
                            >
                              Tube
                            </Typography>
                          </Grid>
                          <Grid ml={2} sx={{ color: "#808080" }}>
                            <Typography
                              sx={{
                                fontSize: "12px",
                                fontWeight: "bold",
                                fontFamily: "League spartan",
                              }}
                            >
                              Dome
                            </Typography>
                          </Grid>
                          <Grid sx={{ color: "#808080" }}>
                            <Typography
                              sx={{
                                fontSize: "12px",
                                fontWeight: "bold",
                                fontFamily: "League spartan",
                              }}
                            >
                              Channel
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          p={1}
                          mt={-3}
                          display={"flex"}
                          flexDirection={"row"}
                          justifyContent={"space-between"}
                        >
                          <Grid sx={{ color: "#4D4D4D" }}>
                            <Typography
                              sx={{
                                fontSize: "12px",
                                fontFamily: "League spartan",
                              }}
                            >
                              {
                                DEVICES_TUBE_NAME[
                                  data?.left_fitted_device?.device_tube
                                ]
                              }
                            </Typography>
                          </Grid>
                          <Grid ml={-4} sx={{ color: "#4D4D4D" }}>
                            <Typography
                              sx={{
                                fontSize: "12px",
                                fontFamily: "League spartan",
                              }}
                            >
                              {
                                DOM_TYPE_NAME[
                                  data?.left_fitted_device?.dom_type
                                ]
                              }
                            </Typography>
                          </Grid>
                          <Grid mr={6} sx={{ color: "#4D4D4D" }}>
                            <Typography
                              sx={{
                                fontSize: "12px",
                                fontFamily: "League spartan",
                              }}
                            >
                              {data?.left_fitted_device?.device_channel}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid
                          mt={1}
                          xs={12}
                          sm={12}
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Button
                            onClick={() => {
                              navigate(
                                `/patient-details/${params.id}/device-selection`,
                                { state: LISTENING_SIDE.LEFT }
                              );
                            }}
                            sx={{
                              fontSize: "20px",
                              textDecoration: "underline",
                            }}
                          >
                            Change
                          </Button>
                        </Grid>
                      </Box>
                    </Grid>
                  </Paper>
                  <CoonectDeviceModule
                    onConnectWithDevice={(data, deviceInfo, disconnectFun) => {
                      dispatch(
                        connectDevice(
                          data,
                          deviceInfo,
                          disconnectFun,
                          fittingLeft.device_side
                        )
                      );
                    }}
                    Component={ConnectButton}
                    onLoadingChange={(loader, message) => {
                      dispatch(
                        changeLoadinfMessages(
                          loader,
                          message,
                          fittingLeft.device_side
                        )
                      );
                    }}
                    onEnableChange={(val) =>
                      dispatch(
                        changeDeviceCompatiblity(val, fittingLeft.device_side)
                      )
                    }
                    onWriteFunctionEnabled={(fun) =>
                      dispatch(
                        onWriteFunctionChange(fun, fittingLeft.device_side)
                      )
                    }
                    onDisconnect={() =>
                      dispatch(disconnectDevice(fittingLeft.device_side))
                    }
                    fitting={fittingLeft}
                  />
                </Box>
                <Avatar
                  sx={{
                    bgcolor: "#1265A1",
                    top: "-15px",
                    width: 24,
                    height: 24,
                    radius: 51,
                    padding: 2,
                    fontSize: 8,
                    m: 1,
                  }}
                >
                  L
                </Avatar>
              </Grid>
            )}
          </Grid>
          <Box
            ml={1}
            sx={{
              height: "26vh",
              flex: 1,
            }}
          >
            <Typography variant="subtitle1" m={3} sx={{ color: "black" }}>
              Fitting History
            </Typography>
            <Box
              sx={{
                borderRadius: "4px",
                border: "1px solid #DDDDDD",
                height: "100%",
                overflow: "hidden",
              }}
            >
              <Grid
                p={1}
                container
                sx={{
                  backgroundColor: "#E8E8E8",
                }}
              >
                <Grid item xs={4.5}>
                  <Typography ml={3} variant="h6" sx={{ color: "black" }}>
                    Date and Time
                  </Typography>
                </Grid>
                <Grid item xs={5}>
                  <Typography variant="h6" sx={{ color: "black" }}>
                    Device Name
                  </Typography>
                </Grid>
              </Grid>

              <ContentContainer noPadding overflow="auto">
                {data?.profiles?.filter(
                  (item) => item.device_side === LISTENING_SIDE.LEFT
                ).length != 0 ? (
                  data?.profiles
                    ?.filter((item) => item.device_side === LISTENING_SIDE.LEFT)
                    .map((item, index) => (
                      <HistoryBox
                        key={item._id}
                        patientData={data}
                        even={index % 2 === 0}
                        {...item}
                      />
                    ))
                ) : (
                  <NoDataComponent
                    message={"No Profile Found"}
                    Icon={Earbuds}
                  />
                )}
              </ContentContainer>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

const PatientDetailedViewCommonUI = ({
  loading,
  data,
  onEdit,
  onNewFitting,
  title,
  setData,
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const { fitting } = useSelector((state) => state);
  const { fittingLeft } = useSelector((state) => state);

  const location = useLocation();
  const [anchorEl, setAnchorEl] = useState(null);

  const detailsView = () => {
    dispatch(
      openModal(
        <PatientInformationViewController data={data} />,
        "lg",
        false,
        "information"
      )
    );
  };

  const letter = (name) => {
    let newName = name?.charAt(0).toUpperCase();
    let flag = false;
    for (let i = 1; i < name?.length; i++) {
      if (name?.charAt(i) == " ") {
        flag = true;
      } else {
        if (flag) {
          newName = newName + " " + name?.charAt(i).toUpperCase();
          flag = false;
        } else {
          newName = newName + name?.charAt(i).toLowerCase();
        }
      }
    }
    return newName;
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const navigate = useNavigate();

  return (
    <>
      <FlexedColumnBox sx={{}}>
        {!loading && (
          <>
            {" "}
            <Stack
              direction={"row"}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Stack direction={"row"}>
                <IconButton
                  sx={{ marginRight: "10px" }}
                  onClick={() => {
                    navigate("/patient-details");
                  }}
                >
                  <ArrowBackIcon sx={{ color: "#2D3B67" }} fontSize="medium" />
                </IconButton>
                <Typography
                  sx={{ textDecoration: "underline" }}
                  color={"#2D3B67"}
                  variant="h3"
                  alignSelf="center"
                >
                  {letter(data?.first_name)}
                </Typography>
                <IconButton onClick={detailsView}>
                  <PermIdentityOutlined
                    sx={{ color: "#2D3B67" }}
                    fontSize="small"
                  />
                </IconButton>
              </Stack>
              <Box mr={3} p={2}>
                {fitting.connected || fittingLeft.connected ? (
                  <Button
                    onClick={onNewFitting}
                    variant="contained"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <Typography variant="h4" lineHeight="10%">
                      <HearingAidIcon />
                    </Typography>
                    &nbsp;
                    <Typography fontWeight={500} variant="h5">
                      In-Person Fitting
                    </Typography>
                  </Button>
                ) : (
                  <Button
                    onClick={() => {
                      dispatch(applyForRemoteFitting(LISTENING_SIDE.BOTH));
                      onNewFitting();
                    }}
                    variant="contained"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <Typography variant="h4" lineHeight="0%">
                      <SettingsRemoteIcon />
                    </Typography>
                    &nbsp;
                    <Typography fontWeight={500} lineHeight="100%" variant="h5">
                      Remote Fitting
                    </Typography>
                  </Button>
                )}
              </Box>
            </Stack>
          </>
        )}
        <OuterContainer sx={{}}>
          {loading && (
            <CenteredBox>
              <CircularProgress />
            </CenteredBox>
          )}
          {!loading && (!data || !data["_id"]) && (
            <NoDataComponent message={title + " not found"} Icon={Person} />
          )}

          {!loading && data && data["_id"] && (
            <>
              <FlexedColumnBox sx={{ flex: 1 }}>
                <PatientAudioGramView onEdit={onEdit} data={data} />
              </FlexedColumnBox>
            </>
          )}
        </OuterContainer>
      </FlexedColumnBox>
    </>
  );
};
export default PatientDetailedViewCommonUI;
